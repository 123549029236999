<template>
  <button
    type="button"
    @click="tcoCalculationCollapsed = !tcoCalculationCollapsed"
    class="collapsable w-100 text-left pa-1"
    :class="tcoCalculationCollapsed ? 'collapsed' : ''"
  >
    <h2>Udregninger af TCO</h2>
    <p>Alle priser opgives ekskl. moms.</p>
    <div>
      <div>
        <div v-if="!tcoCalculationCollapsed">
          <h3 class="mt-0">Grunddata</h3>
          <v-row class="mt-2">
            <v-col>
              <div>
                <strong>Samlet antal biler (inkl. optioner):</strong>
                <div>
                  {{ totalCars }}
                </div>
              </div>
            </v-col>
            <v-col>
              <div>
                <strong>Levering i alt pr. bil:</strong>
                <div>
                  {{ toCurrencyFormat(priceDeliveryPerVehicle(offer)) }}
                </div>
              </div>
            </v-col>
            <v-col>
              <div>
                <strong>Opbygninger i alt pr. bil (fast pris):</strong>
                <div>
                  {{
                    toCurrencyFormat(priceModificationPerVehicle(offer, tender))
                  }}
                </div>
              </div>
            </v-col>
          </v-row>
          <v-row class="mt-4">
            <v-col cols="4">
              <div>
                <strong>SKI's samlede andel:</strong>
                <div>
                  {{ toCurrencyFormat(totalSkiShare(offer, tender)) }}
                </div>
              </div>
            </v-col>
            <v-col cols="4">
              <div>
                <strong>Samlet pris for bil/biler i bytte:</strong>
                <div>
                  {{ sumOfAllCustomerCars }}
                </div>
              </div>
            </v-col>
            <v-col cols="4">
              <div>
                <strong>Tilbagekøbspris efter endt TCO-periode::</strong>
                <div>
                  {{ toCurrencyFormat(offer.data.priceBuyback) }}
                </div>
              </div>
            </v-col>
          </v-row>
          <h3 class="mt-8">TCO</h3>
          <v-row class="mt-2">
            <v-col>
              <div>
                <strong>Investeringsomkostninger i alt:</strong>
                <div>
                  <i>
                    (indkøbspris + evt. udvidet garantiperiode) x antal biler
                  </i>
                  <div>
                    {{
                      `(${priceInclRegistrationPerVehicle(offer)} + ${
                        offer.data.priceServicePeriod
                          ? offer.data.priceServicePeriod
                          : 0
                      }) x ${totalCars} = `
                    }}
                    <span class="float-right">{{
                      toCurrencyFormat(investmentCosts(offer, tender))
                    }}</span>
                  </div>
                </div>
              </div>
            </v-col>
          </v-row>
          <v-row class="mt-2">
            <v-col>
              <div>
                <strong>Driftsomkostninger i alt:</strong>
                <div>
                  <div><i>TCO-periode</i></div>
                  <div v-if="isGasoline">
                    <i>
                      <span class="ml-3 symbol">&Sigma;</span>
                      <span class="ml-1 pb-3">
                        ((årligt kørselsforbrug / brændstofsforbrug x
                        benzinpris) x (1 + diskonteringsrente / 100)<sup>
                          -i</sup>) x antal biler
                      </span>
                      <div class="ml-3">i = 1</div>
                    </i>
                    <div
                      v-for="tcoYear in tender.data.tcoYears ?? 0"
                      :key="tcoYear"
                    >
                      {{
                        `((${tender.data.expectedYearlyDrivingDistance}
                         / ${offer.data.consumptionICE}
                          x ${fuelPriceInYear(offer, tcoYear).toFixed(2)})
                          x (1 + ${tcoVariables.discountRate} / 100)`
                      }}<sup>{{ -tcoYear }}</sup>{{ `) x ${totalCars} = ` }}
                      <span class="float-right">{{
                        toCurrencyFormat(operatingCostsIndividualYear(tcoYear, offer, tender))
                      }}</span>
                      <v-divider v-if="tcoYear === tender.data.tcoYears" />
                    </div>
                  </div>
                  <div v-if="isDiesel">
                    <span class="ml-3 symbol">&Sigma;</span>
                    <i>
                      <span class="ml-1 pb-3">
                        ((årligt kørselsforbrug / brændstofsforbrug x
                        dieselpris) x (1 + diskonteringsrente / 100)<sup>
                          -i</sup>) x antal biler
                      </span>
                      <div class="ml-3">i = 1</div>
                    </i>
                    <div
                      v-for="tcoYear in tender.data.tcoYears ?? 0"
                      :key="tcoYear"
                    >
                      {{
                        `((${tender.data.expectedYearlyDrivingDistance}
                         / ${offer.data.consumptionICE}
                          x ${fuelPriceInYear(offer, tcoYear).toFixed(2)})
                          x (1 + ${tcoVariables.discountRate} / 100)`
                      }}<sup>{{ -tcoYear }}</sup>{{ `) x ${totalCars} = ` }}
                      <span class="float-right">{{
                        toCurrencyFormat(operatingCostsIndividualYear(tcoYear, offer, tender))
                      }}</span>
                      <v-divider v-if="tcoYear === tender.data.tcoYears" />
                    </div>
                  </div>
                  <div v-if="isElectric">
                    <span class="ml-3 symbol">&Sigma;</span>
                    <i>
                      <span
                        v-if="
                          offer.data.chargingSubscription
                            && offer.data.chargingSubscription > 0
                        "
                        class="ml-1 pb-3"
                      >
                        (pris for opladningsabonnement x (1 + diskonteringsrente
                        / 100)<sup> -i</sup>) x antal biler
                      </span>
                      <span v-else class="ml-1 pb-3">
                        (((årligt kørselsforbrug x elforbrug / 1000) x elpris) x
                        (1 + diskonteringsrente / 100)<sup> -i</sup>) x antal
                        biler
                      </span>
                      <div class="ml-3">i = 1</div>
                    </i>
                    <div
                      v-for="tcoYear in tender.data.tcoYears ?? 0"
                      :key="tcoYear"
                    >
                      <div
                        v-if="
                          offer.data.chargingSubscription
                            && offer.data.chargingSubscription > 0
                        "
                      >
                        {{
                          `(${offer.data.chargingSubscription}
                         x (1 + ${tcoVariables.discountRate} / 100)`
                        }}<sup>{{ -tcoYear }}</sup>{{ `) x ${totalCars} = ` }}
                      </div>
                      <div v-else>
                        {{
                          `(((${tender.data.expectedYearlyDrivingDistance}
                         x ${offer.data.consumptionElectric}
                         / 1000) x ${fuelPriceInYear(offer, tcoYear).toFixed(2)})
                          x (1 + ${tcoVariables.discountRate} / 100)`
                        }}<sup>{{ -tcoYear }}</sup>{{ `) x ${totalCars} = ` }}
                        <span class="float-right">{{
                          toCurrencyFormat(operatingCostsIndividualYear(tcoYear, offer, tender))
                        }}</span>
                      </div>
                      <v-divider v-if="tcoYear === tender.data.tcoYears" />
                    </div>
                  </div>
                  <div>
                    <span class="float-right">{{
                      toCurrencyFormat(operatingCosts(offer, tender),
                      )
                    }}</span>
                  </div>
                </div>
              </div>
            </v-col>
          </v-row>
          <v-row class="mt-2">
            <v-col>
              <div>
                <strong>Service, vedligehold og løbende omkostninger i alt:</strong>
                <div>
                  <i>
                    <div>TCO-periode</div>
                    <span class="ml-3 symbol">&Sigma;</span>
                    <span class="ml-1 pb-3">
                      ((årlige totale service pris + grøn ejerafgift) x (1 +
                      diskonteringsrente / 100)<sup> -i</sup>) x antal biler
                    </span>
                    <div class="ml-3">i = 1</div>
                  </i>
                  <div
                    v-for="tcoYear in tender.data.tcoYears ?? 0"
                    :key="tcoYear"
                  >
                    {{
                      `((${totalYearlyServiceCosts(offer, tender)
                        .toFixed(2)
                        .replace(/[.,]00$/, '')} + ${
                        offer.data.greenOwnerTax
                      }) x (1 + ${tcoVariables.discountRate} / 100)`
                    }}<sup>{{ -tcoYear }}</sup>{{ `) x ${totalCars} = ` }}
                    <span class="float-right">{{
                      toCurrencyFormat(
                        serviceCostsIndividualYear(
                          tcoYear,
                          offer,
                          tender,
                        ),
                      )
                    }}</span>
                    <v-divider v-if="tcoYear === tender.data.tcoYears" />
                  </div>
                  <div>
                    <span class="float-right">{{
                      toCurrencyFormat(serviceCosts(offer, tender))
                    }}</span>
                  </div>
                </div>
              </div>
            </v-col>
          </v-row>
          <v-row class="mt-2">
            <v-col>
              <div>
                <strong>Tilbagetagningspris, omkostninger og indtægter i alt:
                </strong>
                <div>
                  <i>
                    -tilbagekøbspris x antal biler x (1 + (diskonteringsrente) /
                    100) <sup>-(TCO-periode)</sup>
                  </i>
                  <div>
                    <span>
                      {{
                        `${
                          (offer.data.priceBuyback === undefined || offer.data.priceBuyback === null)
                            ? 0
                            : -offer.data.priceBuyback
                        } x ${totalCars} x (1 + ${
                          tcoVariables.discountRate
                        } / 100)`
                      }}<sup>{{ -(tender.data.tcoYears ?? 0) }}</sup> =
                    </span>
                    <span class="float-right">{{
                      toCurrencyFormat(takeBackPrice(offer, tender))
                    }}</span>
                  </div>
                </div>
              </div>
            </v-col>
          </v-row>
          <v-row class="mt-2">
            <v-col>
              <div>
                <strong>Samlet TCO (nutidsværdi):</strong>
                <div>
                  <i>
                    investeringsomkostninger + driftsomkostninger + service,
                    vedligehold og løbende omkostninger - tilbagetagningspris,
                    omkostninger og indtægter
                  </i>
                  <div>
                    <span>
                      {{
                        `${toCurrencyFormat(
                          investmentCosts(offer, tender),
                        )} + ${toCurrencyFormat(
                          operatingCosts(offer, tender),
                        )}
                       + ${toCurrencyFormat(
                         serviceCosts(offer, tender),
                        )} - ${toCurrencyFormat(
                          takeBackPrice(offer, tender) * -1,
                        )} = `
                      }}
                    </span>
                    <span class="float-right">{{
                      toCurrencyFormat(
                        investmentCosts(offer, tender)
                          + operatingCosts(offer, tender)
                          + serviceCosts(offer, tender)
                          + takeBackPrice(offer, tender),
                      )
                    }}</span>
                  </div>
                </div>
              </div>
            </v-col>
          </v-row>
        </div>
        <v-row class="mt-2">
          <v-col>
            <div>
              <strong>TCO - Evalueringspris (pris i alt for de anskaffede biler i TCO
                perioden inkl. optioner og SKI's andel):</strong>
              <div>
                <i>
                  antal biler x (levering i alt pr. bil + opbygninger i alt pr.
                  bil) + samlet TCO + SKI-andel - tilbagekøbspris - samlet pris
                  for bil-i-bytte
                </i>
                <div>
                  <span>
                    {{
                      `${totalCars} x (${
                        priceDeliveryPerVehicle(offer)}
                        + ${priceModificationPerVehicle(offer, tender)
                      })
                       + ${toCurrencyFormat(
                         investmentCosts(offer, tender)
                           + operatingCosts(offer, tender)
                           + serviceCosts(offer, tender)
                           + takeBackPrice(offer, tender),
                      )} + ${toCurrencyFormat(totalSkiShare(offer, tender))}
                        - ${toCurrencyFormat(
                          takeBackPriceNonCalculated,
                      )} - ${toCurrencyFormat(sumOfAllCustomerCars)} = `
                    }}
                  </span>
                  <span class="double-underline float-right">
                    <u>{{
                      toCurrencyFormat(calculateTCOPrice(offer, tender))
                    }}</u>
                  </span>
                </div>
              </div>
            </div>
          </v-col>
        </v-row>
      </div>
    </div>
  </button>
</template>

<script setup lang="ts">
import { ref, computed } from 'vue';
import { toCurrencyFormat } from '@dims/components';
import {
  operatingCosts,
  operatingCostsIndividualYear,
  calculateTCOPrice,
  fuelPriceInYear,
  investmentCosts,
  isOfferElectric,
  priceDeliveryPerVehicle,
  priceInclRegistrationPerVehicle,
  priceModificationPerVehicle,
  serviceCosts,
  serviceCostsIndividualYear,
  takeBackPrice,
  tenderTotalCars,
  totalYearlyServiceCosts,
  totalSkiShare,
} from '@/services/utils5086';
import PropellantEnum from '@/models/PropellantTypes';
import { Offer5086 } from '@/models/Offer';
import { Tender5086 } from '@/models/Tender';
import tcoVariables from '@/services/tcoVariables';

const { tender, offer } = defineProps<{ tender: Tender5086, offer: Offer5086 }>();

const tcoCalculationCollapsed = ref(false);

const sumOfAllCustomerCars = computed(() => {
  const od = offer.data;
  return (
    (od.priceBuyCustomersCar1 ?? 0)
      + (od.priceBuyCustomersCar2 ?? 0)
      + (od.priceBuyCustomersCar3 ?? 0)
      + (od.priceBuyCustomersCar4 ?? 0)
      + (od.priceBuyCustomersCar5 ?? 0)
  );
});

const totalCars = computed((): number => tenderTotalCars(offer, tender));

const isGasoline = computed(() => (
  offer.data.propellantType === PropellantEnum.GASOLINE
      || offer.data.propellantType === PropellantEnum.GASOLINE_HYBRID
));

const isDiesel = computed(() => (
  offer.data.propellantType === PropellantEnum.DIESEL
      || offer.data.propellantType === PropellantEnum.DIESEL_HYBRID
));

const isElectric = computed(() => isOfferElectric(offer));

const takeBackPriceNonCalculated = computed(() => offer.data.priceBuyback ?? 0);

</script>

<style scoped>
.symbol {
  font-size: 30px;
}

.double-underline {
  text-decoration-line: underline;
  text-decoration-style: double;
}

.collapsable {
  cursor: pointer;
  break-inside: avoid;
}

.collapsed {
  overflow: hidden;
  position: relative;
  height: 182px;
}

.collapsed::before {
  content: '';
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  background: linear-gradient(transparent 0, rgb(255 255 255 / 0.8));
}
</style>
