enum PriceTypesEnum {
  FIXED_PRICE = 'FixedPrice',
  HOURLY_RATE = 'HourlyRate',
}

export const priceTypes = [
  PriceTypesEnum.FIXED_PRICE,
  PriceTypesEnum.HOURLY_RATE,
];

export const priceTypeLabels = (type: PriceTypesEnum) => {
  switch (type) {
    case PriceTypesEnum.FIXED_PRICE:
      return 'Fastpris';
    case PriceTypesEnum.HOURLY_RATE:
      return 'Timepris';
    default:
      return 'Unknown price type';
  }
};

export default PriceTypesEnum;
