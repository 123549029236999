<template>
  <v-container fluid>
    <v-dialog
      v-if="isDraft && !sandboxMode"
      v-model="confirmationOfferDialog"
      persistent
      scrollable
      max-width="80%"
    >
      <template #activator="{ props }">
        <ArrowButton
          data-cy="send-offer-button"
          :disabled="!validOffer"
          class="primary-button"
          v-bind="props"
        >
          Se og send tilbud
        </ArrowButton>
      </template>
      <v-card>
        <SendOfferConfirmationDialog
          @closeConfirmationDialog="closeOfferConfirmationDialog"
          @makeOfferActive="makeOfferActive"
          :offer="offer"
          :tender="tender"
        />
      </v-card>
    </v-dialog>
    <v-dialog
      v-if="isDraft && !usesOwnDocumentsSpecification && !validOffer && !deadlineExpired"
      v-model="errorDialog"
      width="1000"
    >
      <template #activator="{ props }">
        <span class="clickable text-error ml-4" v-bind="props">
          Der mangler detaljer på tilbuddet
        </span>
      </template>
      <v-card class="pa-5">
        <v-card-title color="primary" class="d-flex ma-0 pt-1">
          <h3 class="ma-0 text-uppercase">Der mangler detaljer på tilbuddet</h3>
          <v-spacer></v-spacer>
          <v-card-actions>
            <v-btn icon @click="errorDialog = false">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-card-actions>
        </v-card-title>
        <v-card-text>
          Følgende felter mangler at blive udfyldt, eller er ikke udfyldt korrekt:
          <div
            class="mt-2 font-weight-bold"
            v-for="(error, idx) in nonValidDetails"
            :key="idx"
          >
            <v-icon>mdi-alert-circle-outline</v-icon><span class="ml-2">{{ error }}</span>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>
    <div
      class="align-self-center ml-3"
      v-if="isDraft && specificationAccepted === false && !sandboxMode && !deadlineExpired"
    >
      <p class="text-black ma-0">
        Vær opmærksom på, at kravspecifikationen ikke er accepteret, og dit
        tilbud som udgangspunkt vil blive vurderet som ukonditionsmæssigt.
      </p>
    </div>
    <div
      class="align-self-center ml-3"
      v-if="isDraft && !sandboxMode && deadlineExpired"
    >
      <p class="text-black ma-0">
        Du kan ikke indsende tilbud, da tilbudsfristen er overskredet
      </p>
    </div>
    <div v-if="offerPublishedDate !== ''">
      <p>
        <strong>Tilbud indsendt {{ offerPublishedDate }}</strong>
      </p>
      <div>
        <p v-if="!deadlineExpired">
          Du kan frem til tilbudsfristen trække dit tilbud tilbage og evt. tilrette og indsende et nyt.
          Det gør du under fanen 'Overblik'. Når tilbudsfristen er udløbet, kan du ikke længere ændre eller tilbagekalde dit tilbud
        </p>
      </div>
      <v-btn
        class="primary-button"
        @click="offerDetailsDialog = !offerDetailsDialog"
      >
        Se dit tilbud
      </v-btn>
      <OfferSpecificationDialog
        v-model="offerDetailsDialog"
        :tender="tender"
        :offer="offer"
      />
    </div>
    <v-form ref="form" :disabled="deadlineExpired">
      <v-card color="primary" class="mt-4 pa-1">
        <v-row no-gutters>
          <v-col v-if="!readonly" class="pr-1">
            <v-card class="pa-10 bg-canvas">
              <v-card-text>
                <h1 class="mt-0 mb-2">Udfyld dit tilbud</h1>
                <div class="mt-2 mb-7">
                  <div v-if="!usesOwnDocumentsSpecification">
                    Byd på indkøbet ved at bekræfte om dit tilbud opfylder
                    kundes kravspecifikation samt udfylde detaljerne i dit
                    tilbud.
                  </div>
                  <div v-else>
                    Byd på indkøbet ved at bekræfte om dit tilbud opfylder
                    kundes kravspecifikation. Kunden har brugt eget materiale.
                    Orienter dig i det offentliggjorte materiale og upload dit
                    tilbud her.
                  </div>
                  <br />
                  <span class="font-weight-bold">Bemærk</span> priser skal
                  afgives ekskl. moms og kundebetaling til SKI, som
                  beregnes automatisk. Se faktura-eksempel nederst.
                  Husk at sikre dig, at SKIs andel fremgår af din faktura til kunden.
                </div>
                <v-card class="pa-5">
                  <v-card-text>
                    <h2 class="font-weight-bold mb-4 mt-0">
                      Kundens kravspecifikation
                    </h2>
                    <h3>
                      Bekræft om dit tilbud opfylder samtlige krav i kundens
                      kravspecifikation:
                      <SpecificationPreviewDialog
                        v-if="!usesOwnDocumentsSpecification"
                        :tender="tender"
                        label="Se kravspecifikation"
                      />
                    </h3>
                    <v-radio-group
                      v-model="specificationAccepted"
                      class="mb-2"
                      hide-details
                      :disabled="!isDraft || store.isCustomer"
                    >
                      <v-radio :value="true" data-cy="specificationAccepted-radio">
                        <template #label>
                          <div>Ja, alle krav er opfyldt</div>
                        </template>
                      </v-radio>
                      <v-radio :value="false">
                        <template #label>
                          <div>
                            Nej, alle krav er
                            <span class="font-weight-bold">ikke</span> opfyldt
                          </div>
                        </template>
                      </v-radio>
                    </v-radio-group>
                    <div
                      v-if="specificationAccepted === false"
                      class="text-red"
                    >
                      <strong>Bemærk:</strong> du har angivet, at dit tilbud ikke opfylder kravene i kravspecifikationen,
                      dit tilbud vil derfor som udgangspunkt blive vurderet som ukonditionsmæssigt.
                    </div>
                  </v-card-text>
                </v-card>

                <v-card class="pa-5 mt-4" ref="offerDetails">
                  <v-card-text>
                    <v-row>
                      <v-col cols="12">
                        <LoadingSpinner :visible="isSaving" loadingMessage="Gemmer" />
                        <div class="d-flex">
                          <h1 class="mt-3 mb-3">
                            {{ isDraft ? 'Udfyld dit tilbud' : 'Dit tilbud' }}
                          </h1>
                          <v-spacer />
                          <v-checkbox
                            color="accent"
                            class="ma-4"
                            label="Vis kravspecifikation"
                            v-model="specificationViewerDialog"
                            v-if="
                              !usesOwnDocumentsSpecification && !sandboxMode
                            "
                          >
                          </v-checkbox>
                        </div>
                        <p v-if="isDraft">
                          Her skal du udfylde detaljerne om dit tilbud.
                        </p>
                        <p v-if="!isDraft">
                          Her kan du se detaljerne i dit tilbud.
                        </p>
                        <hr />
                        <h2 class="my-3">Generelt</h2>
                      </v-col>
                    </v-row>

                    <v-row>
                      <v-col cols="6">
                        <p>
                          <v-text-field
                            variant="outlined"
                            v-model="brand"
                            type="text"
                            hint=""
                            label="Mærke"
                            :rules="[notEmptyRule]"
                            clearable
                            density="compact"
                            :disabled="!isDraft"
                            data-cy="brand"
                          >
                          </v-text-field>
                        </p>
                        <p>
                          <v-text-field
                            variant="outlined"
                            v-model="model"
                            type="text"
                            hint=""
                            label="Model"
                            :rules="[notEmptyRule]"
                            clearable
                            density="compact"
                            :disabled="!isDraft"
                            data-cy="model"
                          >
                          </v-text-field>
                        </p>
                        <p>
                          <v-text-field
                            variant="outlined"
                            v-model="variant"
                            type="text"
                            hint=""
                            label="Variant"
                            clearable
                            :rules="[notEmptyRule]"
                            density="compact"
                            :disabled="!isDraft"
                            data-cy="variant"
                          >
                          </v-text-field>
                        </p>
                      </v-col>
                      <v-col cols="6">
                        <p>
                          <v-text-field
                            variant="outlined"
                            v-model="equipmentLine"
                            type="text"
                            hint=""
                            label="Udstyrslinje"
                            clearable
                            :rules="[notEmptyRule]"
                            density="compact"
                            :disabled="!isDraft"
                            data-cy="equipmentLine"
                          >
                          </v-text-field>
                        </p>
                        <p>
                          <v-text-field
                            variant="outlined"
                            v-model="engineSize"
                            type="text"
                            hint=""
                            label="Motorstørrelse (liter el. cm³)"
                            clearable
                            :rules="[notEmptyRule]"
                            density="compact"
                            :disabled="!isDraft"
                            data-cy="engineSize"
                          >
                          </v-text-field>
                        </p>
                        <p>
                          <v-text-field
                            variant="outlined"
                            v-model="power"
                            type="text"
                            hint=""
                            label="HK/Moment (N-m)"
                            clearable
                            :rules="[notEmptyRule]"
                            density="compact"
                            :disabled="!isDraft"
                            data-cy="power"
                          >
                          </v-text-field>
                        </p>
                      </v-col>
                    </v-row>

                    <v-row v-if="showPropellantTypes || isElectric || isICE">
                      <v-col cols="12">
                        <hr />
                        <h2 class="my-3">Motor og præstation</h2>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="6">
                        <p v-if="showPropellantTypes">
                          <v-select
                            variant="outlined"
                            :items="propellantTypes"
                            v-model="propellantType"
                            label="Brændstoftype"
                            :rules="[notEmptyRule]"
                            density="compact"
                            :disabled="
                              !isDraft
                                || (tender.data.propellantTypes && tender.data.propellantTypes.length === 1)
                            "
                            data-cy="propellantType"
                          ></v-select>
                        </p>
                        <p v-if="isICE">
                          <NumericInput
                            variant="outlined"
                            v-model="consumptionICE"
                            :decimals="2"
                            hint=""
                            suffix="km/l"
                            label="Brændstofforbrug (km/l)"
                            clearable
                            required
                            :minValue="1"
                            density="compact"
                            :disabled="!isDraft"
                            data-cy="consumptionICE"
                          />
                        </p>
                        <p v-if="isElectric">
                          <NumericInput
                            variant="outlined"
                            v-model="consumptionElectric"
                            :decimals="2"
                            suffix="Wh/km"
                            label="Elektrisk forbrug (Wh/km)"
                            clearable
                            required
                            :minValue="1"
                            density="compact"
                            :disabled="!isDraft"
                            data-cy="consumptionElectric"
                          />
                        </p>
                      </v-col>
                      <v-col cols="6">
                        <p v-if="showVehicleClass">
                          <v-select
                            variant="outlined"
                            :items="vehicleClassTypes"
                            v-model="vehicleClass"
                            label="Køretøjsklasse"
                            :rules="[notEmptyRule]"
                            density="compact"
                            :disabled="!isDraft"
                            data-cy="vehicleClass"
                          ></v-select>
                        </p>
                        <p v-if="showEmission">
                          <NumericInput
                            variant="outlined"
                            v-model="emission"
                            :decimals="2"
                            suffix="CO₂ g/km"
                            label="Emission (CO₂ g/km)"
                            hint="Udstødningsemission ved kørsel (CO₂ g/km)"
                            clearable
                            required
                            density="compact"
                            :disabled="!isDraft"
                            data-cy="emission"
                          />
                        </p>
                      </v-col>
                    </v-row>
                    <v-row v-if="!usesOwnDocumentsSpecification">
                      <v-col cols="12">
                        <hr />
                        <h2 class="my-3">Pris - pr. bil</h2>
                        <p v-if="tender.data.priceMaximum">
                          {{
                            getQuestionLabel(
                              '0ea0652a-883d-49fc-89db-ead3d6785f46',
                            )
                          }}:
                          <strong>{{
                            $format.currency(tender.data.priceMaximum)
                          }}</strong>
                          Din pris er
                          <span
                            :class="{
                              'text-red':
                                tender.data.priceMaximum
                                < calculatedPricePerVehicle,
                              'text-green':
                                tender.data.priceMaximum
                                >= calculatedPricePerVehicle,
                            }"
                          >
                            {{ $format.currency(calculatedPricePerVehicle) }}
                          </span>
                        </p>
                      </v-col>
                    </v-row>
                    <v-row v-if="!usesOwnDocumentsSpecification">
                      <v-col cols="6">
                        <p>
                          <NumericInput
                            variant="outlined"
                            v-model="priceTotal"
                            :decimals="2"
                            hint="Prisen afgives uden SKI-andel, denne beregnes automatisk
              og uden registreringsafgift, der anføres til højre"
                            label="Samlet pris pr. bil ekskl. moms og inklusiv ovenfor
                           oplistede generelle krav og krav til udstyr"
                            suffix="DKK"
                            clearable
                            required
                            :minValue="0"
                            density="compact"
                            :disabled="!isDraft"
                            data-cy="priceTotal"
                          />
                        </p>
                        <p v-if="greenOwnerTaxRequired">
                          <NumericInput
                            variant="outlined"
                            v-model="greenOwnerTax"
                            :decimals="2"
                            hint=""
                            label="Grøn ejerafgift pr. år pr. bil"
                            suffix="DKK"
                            clearable
                            required
                            :minValue="0"
                            density="compact"
                            :disabled="!isDraft"
                            data-cy="greenOwnerTax"
                          />
                        </p>
                      </v-col>
                      <v-col cols="6">
                        <p>
                          <NumericInput
                            variant="outlined"
                            v-model="registrationTax"
                            :decimals="2"
                            hint=""
                            label="Registreringsafgift i DKK"
                            suffix="DKK"
                            clearable
                            required
                            :minValue="0"
                            density="compact"
                            :disabled="!isDraft"
                            data-cy="registrationTax"
                          />
                        </p>
                        <p>
                          <NumericInput
                            variant="outlined"
                            v-model="priceDelivery"
                            :decimals="2"
                            hint=""
                            label="Leveringsomkostninger inkl. indregistrering og nummerplade"
                            suffix="DKK"
                            clearable
                            required
                            :minValue="0"
                            density="compact"
                            :disabled="!isDraft"
                            data-cy="priceDelivery"
                          />
                        </p>
                      </v-col>
                    </v-row>

                    <v-row v-if="showVehicleModification">
                      <v-col cols="12">
                        <hr />
                      </v-col>
                    </v-row>

                    <v-row v-if="showVehicleModification">
                      <v-col cols="12">
                        <h2 class="my-3">Opbygning</h2>
                      </v-col>
                    </v-row>
                    <v-row
                      v-if="
                        showVehicleModification
                          && tender.data.vehicleModificationPrice
                            === PriceTypesEnum.FIXED_PRICE
                      "
                    >
                      <v-col cols="6">
                        <p>
                          <NumericInput
                            variant="outlined"
                            v-model="priceVehicleModificationFixed"
                            :decimals="2"
                            hint=""
                            label="Angiv fast samlet pris pr. bil, ekskl. moms"
                            suffix="DKK"
                            clearable
                            required
                            :minValue="1"
                            density="compact"
                            :disabled="!isDraft"
                            data-cy="priceVehicleModificationFixed"
                          />
                        </p>
                      </v-col>
                      <v-col cols="6"></v-col>
                    </v-row>
                    <v-row
                      v-if="
                        showVehicleModification
                          && tender.data.vehicleModificationPrice
                            === PriceTypesEnum.HOURLY_RATE
                      "
                    >
                      <v-col cols="6">
                        <p>
                          <NumericInput
                            variant="outlined"
                            v-model="priceVehicleModificationHourlyRate"
                            :decimals="2"
                            hint=""
                            label="Angiv timepris, ekskl. moms"
                            suffix="DKK"
                            clearable
                            required
                            :minValue="1"
                            density="compact"
                            :disabled="!isDraft"
                            data-cy="priceVehicleModificationHourlyRate"
                          />
                        </p>
                      </v-col>
                      <v-col cols="6">
                        <p>
                          Kundens forventede timeforbrug:
                          {{
                            tender.data.vehicleModificationEstimatedHours
                          }}
                          timer.
                        </p>
                        <p>
                          Samlet pris pr. køretøj:
                          {{
                            $format.currency2(priceModificationPerVehicle(offer, tender))
                          }}
                        </p>
                      </v-col>
                    </v-row>

                    <v-row v-if="showService">
                      <v-col cols="12">
                        <hr />
                      </v-col>
                    </v-row>
                    <v-row v-if="showService">
                      <v-col cols="6">
                        <h2 class="my-3">
                          Service - pris pr. køretøj for hele TCO-perioden
                        </h2>
                        <p>
                          TCO-periode, antal år:
                          <strong>{{ tender.data.tcoYears }}</strong>
                        </p>
                      </v-col>
                      <v-col cols="6">
                        <h3 class="my-3">
                          Samlet årlig pris for service pr. bil (ekskl. evt. udvidet
                          garanti):
                          <strong>
                            {{ $format.currency2(totalYearlyServiceCosts(offer, tender)) }}
                            DKK
                          </strong>
                        </h3>
                      </v-col>
                    </v-row>
                    <v-row v-if="showService">
                      <v-col cols="6">
                        <p v-if="tender.data.serviceAgreementDescriptionPeriod">
                          <NumericInput
                            variant="outlined"
                            v-model="priceService"
                            :decimals="2"
                            hint="Inkl. alle elementer angivet i servicebogen med arbejdstid
                            og materialer indeholdt i prisen for serviceaftalen."
                            persistent-hint
                            :label="
                              getQuestionLabel(
                                '458eb772-624c-40b0-b11d-79a998a1aa11',
                              )
                            "
                            suffix="DKK"
                            clearable
                            required
                            :minValue="1"
                            density="compact"
                            :disabled="!isDraft"
                            data-cy="priceService"
                          />
                        </p>
                        <p v-if="tender.data.repairmentAgreementPeriod">
                          <NumericInput
                            variant="outlined"
                            v-model="priceRepairs"
                            :decimals="2"
                            :hint="
                              getQuestionLabel(
                                'dfa7879d-9cac-49fb-8d61-017498c04ffc',
                              )
                            "
                            persistent-hint
                            label="Reparationsaftale"
                            suffix="DKK"
                            clearable
                            required
                            :minValue="1"
                            density="compact"
                            :disabled="!isDraft"
                            data-cy="repairmentAgreementPeriod"
                          />
                        </p>
                        <p v-if="tender.data.pickupDeliverServicePeriod">
                          <NumericInput
                            variant="outlined"
                            v-model="priceCollectDeliverService"
                            :decimals="2"
                            :hint="
                              getQuestionLabel(
                                '615e5d8c-8b33-4bf7-b0ff-8e272bd7e1b1',
                              )
                            "
                            persistent-hint
                            label="Hente/bringe-service"
                            suffix="DKK"
                            clearable
                            required
                            :minValue="1"
                            density="compact"
                            :disabled="!isDraft"
                            data-cy="priceCollectDeliverService"
                          />
                        </p>
                      </v-col>
                      <v-col cols="6">
                        <p v-if="tender.data.tireAgreementPeriod">
                          <NumericInput
                            variant="outlined"
                            v-model="priceTireAgreement"
                            :decimals="2"
                            :hint="
                              getQuestionLabel(
                                'e4c22f49-7619-47ba-a21b-3dafd2b26964',
                              )
                            "
                            persistent-hint
                            label="Dækaftale"
                            suffix="DKK"
                            clearable
                            required
                            :minValue="1"
                            density="compact"
                            :disabled="!isDraft"
                            data-cy="tireAgreementPeriod"
                          />
                        </p>
                        <p
                          v-if="isElectric && tender.data.chargingSubscription"
                        >
                          <NumericInput
                            variant="outlined"
                            v-model="chargingSubscription"
                            :decimals="2"
                            hint=""
                            suffix="DKK"
                            :label="
                              getQuestionLabel(
                                '6020982b-b249-4d27-9131-1c013c876dd0',
                              )
                            "
                            clearable
                            required
                            :minValue="1"
                            density="compact"
                            :disabled="!isDraft"
                            data-cy="chargingSubscription"
                          />
                        </p>
                        <p v-if="tender.data.extendedWarrantyPeriod">
                          <NumericInput
                            variant="outlined"
                            v-model="priceServicePeriod"
                            :decimals="2"
                            :hint="
                              getQuestionLabel(
                                'c76887e6-03f3-4a8b-8db4-22aeea39f977',
                              )
                            "
                            persistent-hint
                            label="Udvidet garantiperiode"
                            suffix="DKK"
                            clearable
                            required
                            :minValue="1"
                            density="compact"
                            :disabled="!isDraft"
                            data-cy="extendedWarrantyPeriod"
                          />
                        </p>
                      </v-col>
                    </v-row>

                    <v-row v-if="showOthers">
                      <v-col cols="12">
                        <hr />
                      </v-col>
                    </v-row>
                    <v-row v-if="showOthers">
                      <v-col cols="12">
                        <h2 class="my-3">Øvrige - pris pr. bil</h2>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="6">
                        <p
                          v-if="
                            tender.data.deliveryCondition
                              === DeliveryConditionTypesEnum.DELIVERY_AT_CUSTOMER
                          "
                        >
                          <NumericInput
                            variant="outlined"
                            v-model="priceDeliveryAtCustomer"
                            :decimals="2"
                            hint=""
                            label="Levering hos ordregiver"
                            suffix="DKK"
                            clearable
                            required
                            :minValue="1"
                            density="compact"
                            :disabled="!isDraft"
                            data-cy="priceDeliveryAtCustomer"
                          />
                        </p>
                        <p v-if="tender.data.chargingStationPrice">
                          <NumericInput
                            variant="outlined"
                            v-model="chargingStationPrice"
                            :decimals="2"
                            suffix="DKK"
                            :label="
                              getQuestionLabel(
                                '5a5b6df1-1a41-4c15-8606-2328a021b70a',
                              )
                            "
                            clearable
                            required
                            :minValue="1"
                            density="compact"
                            :disabled="!isDraft"
                            data-cy="chargingStationPrice"
                          />
                        </p>
                      </v-col>
                      <v-col cols="6"></v-col>
                    </v-row>

                    <v-row v-if="tender.data.priceBuyback">
                      <v-col cols="6">
                        <p>
                          <NumericInput
                            variant="outlined"
                            v-model="priceBuyback"
                            :decimals="2"
                            hint=""
                            label="Tilbagekøbspris efter endt TCO-periode, med service overholdt"
                            suffix="DKK"
                            clearable
                            required
                            :minValue="1"
                            density="compact"
                            :disabled="!isDraft"
                            data-cy="priceBuyback"
                          />
                        </p>
                      </v-col>
                      <v-col cols="6"></v-col>
                    </v-row>

                    <v-row v-if="anyCustomerCars">
                      <v-col cols="12">
                        <h2 class="my-3">Bil i bytte - pris pr. bil</h2>
                      </v-col>
                    </v-row>
                    <v-row v-if="tender.data.descriptionCustomerCar1">
                      <v-col cols="6">
                        <p>Kundens beskrivelse af bil i bytte:</p>
                        <p>
                          {{ tender.data.descriptionCustomerCar1 }}
                        </p>
                        <p>
                          <NumericInput
                            variant="outlined"
                            v-model="priceBuyCustomersCar1"
                            :decimals="2"
                            hint=""
                            label="Pris for køb af kundens bil"
                            suffix="DKK"
                            clearable
                            required
                            :minValue="1"
                            density="compact"
                            :disabled="!isDraft"
                            data-cy="priceBuyCustomersCar1"
                          />
                        </p>
                      </v-col>
                      <v-col
                        v-if="tender.data.descriptionCustomerCar2"
                        cols="6"
                      >
                        <br />
                        <p class="mt-4">
                          {{ tender.data.descriptionCustomerCar2 }}
                        </p>
                        <p>
                          <NumericInput
                            variant="outlined"
                            v-model="priceBuyCustomersCar2"
                            :decimals="2"
                            hint=""
                            label="Pris for køb af kundens bil"
                            suffix="DKK"
                            clearable
                            required
                            :minValue="1"
                            density="compact"
                            :disabled="!isDraft"
                            data-cy="descriptionCustomerCar2"
                          />
                        </p>
                      </v-col>
                    </v-row>
                    <v-row v-if="tender.data.descriptionCustomerCar3">
                      <v-col cols="6">
                        <p>
                          {{ tender.data.descriptionCustomerCar3 }}
                        </p>
                        <p>
                          <NumericInput
                            variant="outlined"
                            v-model="priceBuyCustomersCar3"
                            :decimals="2"
                            hint=""
                            label="Pris for køb af kundens bil"
                            suffix="DKK"
                            clearable
                            required
                            :minValue="1"
                            density="compact"
                            :disabled="!isDraft"
                            data-cy="descriptionCustomerCar3"
                          />
                        </p>
                      </v-col>
                      <v-col
                        v-if="tender.data.descriptionCustomerCar4"
                        cols="6"
                      >
                        <p>
                          {{ tender.data.descriptionCustomerCar4 }}
                        </p>
                        <p>
                          <NumericInput
                            variant="outlined"
                            v-model="priceBuyCustomersCar4"
                            :decimals="2"
                            hint=""
                            label="Pris for køb af kundens bil"
                            suffix="DKK"
                            clearable
                            required
                            :minValue="1"
                            density="compact"
                            :disabled="!isDraft"
                            data-cy="descriptionCustomerCar4"
                          />
                        </p>
                      </v-col>
                    </v-row>
                    <v-row v-if="tender.data.descriptionCustomerCar5">
                      <v-col cols="6">
                        <p>
                          {{ tender.data.descriptionCustomerCar5 }}
                        </p>
                        <p>
                          <NumericInput
                            variant="outlined"
                            v-model="priceBuyCustomersCar5"
                            :decimals="2"
                            hint=""
                            label="Pris for køb af kundens bil"
                            suffix="DKK"
                            clearable
                            required
                            :minValue="1"
                            density="compact"
                            :disabled="!isDraft"
                            data-cy="descriptionCustomerCar5"
                          />
                        </p>
                      </v-col>
                      <v-col cols="6"></v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
              </v-card-text>
            </v-card>
          </v-col>

          <v-col v-if="specificationViewerDialog">
            <v-card
              class="rounded pa-7 scroller bg-canvas"
              :style="specificationViewerStyle"
            >
              <v-card-text>
                <SpecificationViewer
                  :tender="tender"
                  @loading="loading"
                ></SpecificationViewer>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>

        <v-row v-if="!sandboxMode">
          <v-col>
            <v-card class="rounded pa-7 bg-canvas">
              <OfferMaterial
                :offer="offer"
                :tender="tender"
                :description="
                  usesOwnDocumentsSpecification
                    ? 'Tilføj relevante dokumenter'
                    : ''
                "
              />
            </v-card>
          </v-col>
        </v-row>
        <v-row v-if="!usesOwnDocumentsSpecification">
          <v-col>
            <v-card class="rounded pa-7 bg-canvas">
              <InvoiceExample
                :tender="tender"
                :offer="offer"
                description="Med priserne ovenfor kan du her se et
                     eksempel på en faktura til kunden:"
              />
            </v-card>
          </v-col>
        </v-row>
        <v-row v-if="!usesOwnDocumentsSpecification">
          <v-col>
            <v-card class="rounded pa-7 bg-canvas">
              <TcoCalculationViewer :tender="tender" :offer="offer">
              </TcoCalculationViewer>
            </v-card>
          </v-col>
        </v-row>
      </v-card>
    </v-form>
    <LoadingSpinner :visible="isLoading" :loadingMessage="loadingMessage" />
  </v-container>
</template>

<script setup lang="ts">
import { ref, computed, onMounted, watch } from 'vue';
import {
  useStore,
  OfferValidator,
  OfferMaterial,
  Question,
  UpdateOfferRequest,
  SpecificationPreviewDialog,
  usesOwnDocumentsSpecification as getUsesOwnDocumentsSpecification,
  SendOfferConfirmationDialog,
  useBackend,
  dateUtils,
  tenderHelper,
  notEmptyRule,
  hasValue,
  injectRequired,
  injectionKeys,
} from '@dims/components';
import { VForm } from 'vuetify/components';
import { propellantTypes as propellantTypeList } from '@/models';
import SpecificationViewer from '@/components/Offers/Specification/SpecificationViewer.vue';
import {
  isPropellantElectric,
  isPropellantICE,
  propellantTypeFromLabel,
  propellantTypeLabels,
} from '@/models/PropellantTypes';
import { Offer5086, OfferData5086 } from '@/models/Offer';
import DeliveryConditionTypesEnum from '@/models/DeliveryConditionTypes';
import InvoiceExample from '@/components/Offers/Specification/InvoiceExample.vue';
import PriceTypesEnum from '@/models/PriceTypes';
import {
  contractSum,
  priceModificationPerVehicle,
  totalYearlyServiceCosts,
} from '@/services/utils5086';
import VehicleTypesEnum from '@/models/VehicleTypes';
import VehicleClassEnum, {
  vehicleClassTypeFromLabel,
  vehicleClassTypeLabels,
} from '@/models/VehicleClassTypes';
import TcoCalculationViewer from '@/components/Offers/Specification/TcoCalculationViewer.vue';
import { Tender5086 } from '@/models/Tender';
import OfferSpecificationDialog from '../Specification/OfferSpecificationDialog.vue';

type OfferDataPropertyName = keyof OfferData5086;

const { tender, offer, readonly = false, sandboxMode = false } = defineProps<{
  tender: Tender5086,
  offer: Offer5086,
  readonly?: boolean,
  sandboxMode?: boolean,
}>();
const offerValidator: OfferValidator = injectRequired(injectionKeys.offerValidatorKey);
const store = useStore();
const backend = useBackend();
const loadingMessage = ref('Vent venligst');
const isLoading = ref(false);
const isSaving = ref(false);
const debounceSaveTimeoutId = ref(0);
const confirmationOfferDialog = ref(false);
const questions = ref<Question[]>([]);
const specificationViewerStyle = ref({});
const specificationViewerDialog = ref(false);
const offerDetailsDialog = ref(false);
const errorDialog = ref(false);
// Changes to data which have not been saved to backend yet
// Only relevant during saving
const pendingChanges = new Set<OfferDataPropertyName>();
// Local copy of offer data. Edits are also pushed to underlying Offer data.
// (We can't edit directly on the Offer data, since delayed signalR updates might cause lost edits.)
// Assigned in created handler
// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
const editedData = ref<OfferData5086>(null!);

resetEditing();

const specificationAccepted = computed({
  get(): boolean|undefined {
    return offer.specificationAccepted ?? undefined;
  },
  set(value: boolean|undefined) {
    if (value !== undefined && value) {
      void acceptSpecification();
    } else {
      void rejectSpecification();
    }
  },
});

function resetEditing() {
  // create a copy of offer data with no edited fields
  editedData.value = { };
  pendingChanges.clear();
}

onMounted(async () => {
  await fetchQuestionnaire();
});

const validOffer = computed(() => (
  offerValidator.areOfferDetailsValid(offer, tender)
      && !tenderHelper.isDeadlineExpired(tender)
      && specificationAccepted.value !== undefined
));

const nonValidDetails = computed(() => offerValidator.allNonValidDetails(offer, tender));

const deadlineExpired = computed(() => tenderHelper.isDeadlineExpired(tender));

const offerPublishedDate = computed((): string => {
  const date = offer.publishedDate;
  return dateUtils.shortDateTimeFormat(date);
});

const usesOwnDocumentsSpecification = computed((): boolean => getUsesOwnDocumentsSpecification(tender));

const showPropellantTypes = computed((): boolean => (tender.data.propellantTypes ?? []).length > 0);

const showVehicleClass = computed((): boolean => tender.data.vehicleType !== VehicleTypesEnum.OTHER_VEHICLE);

const showEmission = computed((): boolean => tender.data.vehicleType !== VehicleTypesEnum.OTHER_VEHICLE);

const showService = computed((): boolean => {
  const td = tender.data;
  return (
    !!td.serviceAgreementDescriptionPeriod
      || !!td.repairmentAgreementPeriod
      || !!td.pickupDeliverServicePeriod
      || !!td.extendedWarrantyPeriod
      || !!td.tireAgreementPeriod
  );
});

const isElectric = computed((): boolean => {
  const od = offer.data;
  if (od.propellantType) {
    return isPropellantElectric(od.propellantType);
  }
  const pts = tender.data.propellantTypes ?? [];
  if (pts.length === 1 && pts[0] !== undefined) {
    return isPropellantElectric(pts[0]);
  }
  return false;
});

const anyCustomerCars = computed((): boolean => {
  const hasText = (value: string | undefined | null): boolean => value !== undefined && value !== null && value !== '';
  const td = tender.data;
  return (
    hasText(td.descriptionCustomerCar1)
      || hasText(td.descriptionCustomerCar2)
      || hasText(td.descriptionCustomerCar3)
      || hasText(td.descriptionCustomerCar4)
      || hasText(td.descriptionCustomerCar5)
  );
});

const showOthers = computed((): boolean => {
  const td = tender.data;
  return (
    td.deliveryCondition
        === DeliveryConditionTypesEnum.DELIVERY_AT_CUSTOMER
      || (isElectric.value && hasValue(td.chargingStationPrice))
      || td.priceBuyback === true
      || anyCustomerCars.value
  );
});

const showVehicleModification = computed((): boolean => {
  const { data } = tender;
  return data.vehicleModification === true;
});

const isICE = computed((): boolean => {
  const od = offer.data;
  if (od.propellantType) {
    return isPropellantICE(od.propellantType);
  }
  const pts = tender.data.propellantTypes ?? [];
  if (pts.length === 1 && pts[0] !== undefined) {
    return isPropellantICE(pts[0]);
  }
  return false;
});

const propellantTypes = computed((): string[] => {
  const td = tender.data;
  if (td.propellantTypes) {
    return td.propellantTypes.map((p) => propellantTypeLabels(p));
  }
  return propellantTypeList.map((p) => propellantTypeLabels(p));
});

const vehicleClassTypes = computed((): string[] => {
  const td = tender.data;
  switch (td.vehicleType) {
    case VehicleTypesEnum.PEOPLE_MOVER:
      return [
        vehicleClassTypeLabels(VehicleClassEnum.M1),
        vehicleClassTypeLabels(VehicleClassEnum.Other),
      ];
    case VehicleTypesEnum.LEASING:
      return [
        vehicleClassTypeLabels(VehicleClassEnum.M1),
        vehicleClassTypeLabels(VehicleClassEnum.M2),
        vehicleClassTypeLabels(VehicleClassEnum.N1),
        vehicleClassTypeLabels(VehicleClassEnum.Other),
      ];
    case VehicleTypesEnum.UTILITY_VEHICLE:
      return [
        vehicleClassTypeLabels(VehicleClassEnum.M2),
        vehicleClassTypeLabels(VehicleClassEnum.N1),
        vehicleClassTypeLabels(VehicleClassEnum.Other),
      ];
    default:
      return [];
  }
});

const vehicleClass = computed({
  get() {
    const { vehicleClassType } = offer.data;
    if (vehicleClassType) {
      return vehicleClassTypeLabels(vehicleClassType);
    }
    return '';
  },
  set(label: string) {
    const vc = vehicleClassTypeFromLabel(label);
    if (vc) {
      setValue('vehicleClassType', vc);
    }
  },
});

const emission = computed({
  get(): number | undefined {
    return getValue('emission');
  },
  set(value: number | undefined) {
    setValue('emission', value);
  },
});

const isDraft = computed((): boolean => offer.status === 'Draft');

const brand = computed({
  get() {
    return getValue('brand') ?? '';
  },

  set(value: string) {
    setValue('brand', value);
  },
});

const model = computed({
  get() {
    return getValue('model') ?? '';
  },

  set(value: string) {
    setValue('model', value);
  },
});

const consumptionICE = computed({
  get(): number | undefined {
    return getValue('consumptionICE');
  },
  set(value: number | undefined) {
    setValue('consumptionICE', value);
  },
});

const consumptionElectric = computed({
  get(): number | undefined {
    return getValue('consumptionElectric');
  },
  set(value: number | undefined) {
    setValue('consumptionElectric', value);
  },
});

const chargingStationPrice = computed({
  get(): number | undefined {
    return getValue('chargingStationPrice');
  },
  set(value: number | undefined) {
    setValue('chargingStationPrice', value);
  },
});

const chargingSubscription = computed({
  get(): number | undefined {
    return getValue('chargingSubscription');
  },
  set(value: number | undefined) {
    setValue('chargingSubscription', value);
  },
});

const priceTotal = computed({
  get(): number | undefined {
    return getValue('priceTotal');
  },
  set(value: number | undefined) {
    setValue('priceTotal', value);
  },
});

const greenOwnerTax = computed({
  get(): number | undefined {
    return getValue('greenOwnerTax');
  },
  set(value: number | undefined) {
    setValue('greenOwnerTax', value);
  },
});

const registrationTax = computed({
  get(): number | undefined {
    return getValue('registrationTax');
  },

  set(value: number | undefined) {
    setValue('registrationTax', value);
  },
});

const priceVehicleModificationFixed = computed({
  get(): number | undefined {
    return getValue('priceVehicleModificationFixed');
  },

  set(value: number | undefined) {
    setValue('priceVehicleModificationFixed', value);
  },
});

const priceVehicleModificationHourlyRate = computed({
  get(): number | undefined {
    return getValue('priceVehicleModificationHourlyRate');
  },

  set(value: number | undefined) {
    setValue('priceVehicleModificationHourlyRate', value);
  },
});

const priceService = computed({
  get(): number | undefined {
    return getValue('priceService');
  },

  set(value: number | undefined) {
    setValue('priceService', value);
  },
});

const priceRepairs = computed({
  get(): number | undefined {
    return getValue('priceRepairs');
  },

  set(value: number | undefined) {
    setValue('priceRepairs', value);
  },
});

const priceCollectDeliverService = computed({
  get(): number | undefined {
    return getValue('priceCollectDeliverService');
  },

  set(value: number | undefined) {
    setValue('priceCollectDeliverService', value);
  },
});

const priceServicePeriod = computed({
  get(): number | undefined {
    return getValue('priceServicePeriod');
  },

  set(value: number | undefined) {
    setValue('priceServicePeriod', value);
  },
});

const priceTireAgreement = computed({
  get(): number | undefined {
    return getValue('priceTireAgreement');
  },

  set(value: number | undefined) {
    setValue('priceTireAgreement', value);
  },
});

const priceDelivery = computed({
  get(): number | undefined {
    return getValue('priceDelivery');
  },

  set(value: number | undefined) {
    setValue('priceDelivery', value);
  },
});

const priceDeliveryAtCustomer = computed({
  get(): number | undefined {
    return getValue('priceDeliveryAtCustomer');
  },

  set(value: number | undefined) {
    setValue('priceDeliveryAtCustomer', value);
  },
});

const priceBuyback = computed({
  get(): number | undefined {
    return getValue('priceBuyback');
  },
  set(value: number | undefined) {
    setValue('priceBuyback', value);
  },
});

const variant = computed({
  get() {
    return getValue('variant') ?? '';
  },
  set(value: string) {
    setValue('variant', value);
  },
});

const equipmentLine = computed({
  get() {
    return getValue('equipmentLine') ?? '';
  },
  set(value: string) {
    setValue('equipmentLine', value);
  },
});

const engineSize = computed({
  get() {
    return getValue('engineSize') ?? '';
  },
  set(value: string) {
    setValue('engineSize', value);
  },
});

const power = computed({
  get() {
    return getValue('power') ?? '';
  },
  set(value: string) {
    setValue('power', value);
  },
});

const propellantType = computed({
  get() {
    const offerPropellantType = offer.data.propellantType;
    if (offerPropellantType) {
      return propellantTypeLabels(offerPropellantType);
    }
    const pts = tender.data.propellantTypes ?? [];
    if (pts.length === 1 && pts[0] !== undefined) {
      return propellantTypeLabels(pts[0]);
    }
    return '';
  },
  set(label: string) {
    const pt = propellantTypeFromLabel(label);
    if (pt) {
      setValue('propellantType', pt);
    }
  },
});

const greenOwnerTaxRequired = computed(() => {
  // The field indicate if the tender is *exempt* from the green tax
  // The value 'nej' (no) indicate no exemption, i.e. tax is required
  const greenOwnerTaxValue = (
    tender.data.greenOwnerTax ?? ''
  ).toLowerCase();
  return greenOwnerTaxValue === 'nej';
});

const priceBuyCustomersCar1 = computed({
  get() {
    return getValue('priceBuyCustomersCar1') ?? 0;
  },
  set(value: number) {
    setValue('priceBuyCustomersCar1', value);
  },
});

const priceBuyCustomersCar2 = computed({
  get() {
    return getValue('priceBuyCustomersCar2') ?? 0;
  },
  set(value: number) {
    setValue('priceBuyCustomersCar2', value);
  },
});

const priceBuyCustomersCar3 = computed({
  get() {
    return getValue('priceBuyCustomersCar3') ?? 0;
  },
  set(value: number) {
    setValue('priceBuyCustomersCar3', value);
  },
});

const priceBuyCustomersCar4 = computed({
  get() {
    return getValue('priceBuyCustomersCar4') ?? 0;
  },
  set(value: number) {
    setValue('priceBuyCustomersCar4', value);
  },
});

const priceBuyCustomersCar5 = computed({
  get() {
    return getValue('priceBuyCustomersCar5') ?? 0;
  },

  set(value: number) {
    setValue('priceBuyCustomersCar5', value);
  },
});

const calculatedPricePerVehicle = computed((): number => {
  const od = offer.data;
  return (
    (od.priceTotal ?? 0)
      + (od.registrationTax ?? 0)
      + (od.priceDelivery ?? 0)
      + (od.priceDeliveryAtCustomer ?? 0)
  );
});

const form = ref<VForm>();

watch(
  () => offer.id,
  () => {
    resetEditing();
    // Vuetify adds this method directly to the HTMLFormElement
    form.value?.resetValidation();
  },
);

async function makeOfferActive(signerName: string) {
  closeOfferConfirmationDialog();
  if (offer.status === 'Draft' && signerName) {
    const update: UpdateOfferRequest = { signerName };
    loadingMessage.value = 'Sender tilbud';
    isLoading.value = true;
    try {
      await store.updateOfferAction(offer, update);
      await store.publishOfferAction(offer);
      await backend.markerService.createMarker(offer);
    } catch (error) {
      console.error(error);
      store.setSnackbarText('Kan ikke sende tilbud');
    } finally {
      isLoading.value = false;
    }
  }
}

function closeOfferConfirmationDialog() {
  confirmationOfferDialog.value = false;
}

async function fetchQuestionnaire() {
  if (!tender.data.usesOwnSpecification) {
    loadingMessage.value = 'Henter kravspecifikation';
    isLoading.value = true;
    try {
      const questionnaire = await backend.documentDrafterService.getReadonlyQuestionnaire(
        tender.id,
        'specification',
      );
      questions.value = questionnaire.content.questions;
    } catch (error) {
      console.error(error);
      store.setSnackbarText('Kunne ikke hente kravspecifikation.');
    } finally {
      isLoading.value = false;
    }
  }
}

function getQuestionLabel(id: string): string {
  const text = questions.value.find((q) => q.questionId === id)?.cleanText ?? '';
  return text.replace(/(<([^>]+)>)/gi, '');
}

/* All fields edited by the user is set in editedData and in underlying Order.data */
function setValue<Key extends OfferDataPropertyName>(name: Key, value: OfferData5086[Key]) {
  const { data } = offer;
  editedData.value[name] = value;
  data[name] = value;
  pendingChanges.add(name);
  scheduleSave();
}

/* Will fetch value from editedData if it has been edited by user,
  otherwise from underlying Offer.data.
  That way, changes by other users will be reflected
  as long as the field have not been edited by the current user.
*/
function getValue<Key extends OfferDataPropertyName>(name: Key) {
  const edited = editedData.value[name];
  if (edited !== undefined) {
    return edited;
  }
  return offer.data[name] ?? undefined;
}

function commitEdited<Key extends OfferDataPropertyName>(name: Key) {
  offer.data[name] = editedData.value[name];
}

function scheduleSave() {
  if (debounceSaveTimeoutId.value) {
    window.clearTimeout(debounceSaveTimeoutId.value);
  }
  debounceSaveTimeoutId.value = window.setTimeout(() => {
    void saveDraftNow();
  }, 2000);
}

async function saveDraftNow() {
  // The underlying offer.data could have lost updates due to SignalR updates
  // so we write all edited fields again
  commitAllEdited();
  isSaving.value = true;
  try {
    pendingChanges.clear();
    if (!sandboxMode) {
      const update: UpdateOfferRequest = {
        contractSum: contractSum(offer, tender),
        skiDiscountShare: calculateNewSKIDiscountShare(offer),
        data: offer.data,
        specificationAccepted: offer.specificationAccepted,
        signerName: offer.signer?.fullName,
      };
      await store.updateOfferAction(offer, update);
    }
    // some fields could have been edited during update
    // since update returns the saved offer, we have to set them again
    if (pendingChanges.size > 0) {
      for (const key of pendingChanges) {
        commitEdited(key);
      }
      scheduleSave();
    }
  } catch (error) {
    console.error(error);
    store.setSnackbarText('Kunne ikke gemme.');
  } finally {
    isSaving.value = false;
  }
}

function commitAllEdited() {
  for (const key of Object.keys(editedData.value)) {
    commitEdited(key as OfferDataPropertyName);
  }
}

function calculateNewSKIDiscountShare(offer5086: Offer5086): number | undefined {
  const td = tender.data;
  const od = offer5086.data;
  if (od.priceTotal && td.carsBoughtInitially) {
    const totalServicePrice = (od.priceService ?? 0)
        + (od.priceRepairs ?? 0)
        + (od.priceTireAgreement ?? 0)
        + (od.priceCollectDeliverService ?? 0);
    const modificationFixed = od.priceVehicleModificationFixed ?? 0;
    return (
      (od.priceTotal + totalServicePrice + modificationFixed)
        * 0.01
        * td.carsBoughtInitially
    );
  }
  return undefined;
}

async function rejectSpecification() {
  console.info('rejectSpecification');
  offer.specificationAccepted = false;
  await saveDraftNow();
}

async function acceptSpecification() {
  console.info('acceptSpecification');
  offer.specificationAccepted = true;
  await saveDraftNow();
}

function loading(isLoading1: boolean) {
  isLoading.value = isLoading1;
}

</script>

<style scoped>
.text-black :deep(label) {
  color: black;
}

.scroller {
  overflow: scroll;
}
</style>
