<template>
  <CreateTenderDialog
    v-if="store.myTendersFetched"
    :tender="tender"
    :tender-stub="tenderStub"
    :create-button-disabled="isCreateTenderButtonDisabled()"
    :createAsTemplate="createAsTemplate"
  >
    <template #create-tender-wizard-step>
      <CreateTenderWizardStep :createAsTemplate="createAsTemplate"></CreateTenderWizardStep>
    </template>
  </CreateTenderDialog>
</template>

<script setup lang="ts">
import { computed } from 'vue';
import { CreateTenderDialog, Tender, useStore } from '@dims/components';
import CreateTenderWizardStep from '@/components/create-tender/CreateTenderWizardStep.vue';
import { useStore5086 } from '@/store/store5086';
import { tenderStub as tenderStubDefault } from '@/services';

const { tender = null, createAsTemplate = false } = defineProps<{
  /** Only when the create/clone button is on an existing tender */
  tender?: Tender | null,
  createAsTemplate?: boolean,
}>();
const store = useStore();
const store5086 = useStore5086();

function isCreateTenderButtonDisabled() {
  const { draftTender } = store5086;
  return (
    (!draftTender
        || draftTender.data.propellantTypes?.length === 0
        || !draftTender.awardCriteriaType
        || !draftTender.description
        || !draftTender.data.region)
  );
}

const tenderStub = computed(() => ({ ...tenderStubDefault, agreementName: store.agreementId }));

</script>
