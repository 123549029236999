<template>
  <div>
    <v-row style="width:500px">
      <v-col
        class="py-0"
        cols="6"
        v-for="(type, index) of propellantTypes"
        :key="index"
      >
        <v-checkbox
          density="compact"
          :hide-details="true"
          class="ml-6"
          color="accent"
          v-model="value"
          :label="getLabel(type)"
          :value="type"
        >
        </v-checkbox>
      </v-col>
    </v-row>
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue';
import { PropellantType } from '@/models';
import PropellantEnum, { propellantTypeLabels } from '@/models/PropellantTypes';
import { useStore5086 } from '@/store/store5086';

const store5086 = useStore5086();
const propellantTypes = [
  PropellantEnum.GASOLINE,
  PropellantEnum.HYDROGEN,
  PropellantEnum.DIESEL,
  PropellantEnum.PLUGIN_HYBRID,
  PropellantEnum.BIOGAS,
  PropellantEnum.GASOLINE_HYBRID,
  PropellantEnum.ELECTRICITY,
  PropellantEnum.DIESEL_HYBRID,
];

const value = computed({
  get() {
    return store5086.draftTender?.data.propellantTypes ?? [];
  },
  set(type) {
    if (store5086.draftTender) {
      store5086.setDraftTender({
        ...store5086.draftTender,
        data: { ...store5086.draftTender.data, propellantTypes: type },
      });
    }
  },
});

function getLabel(type: PropellantType): string {
  return propellantTypeLabels(type);
}

</script>
