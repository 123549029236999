import { Offer, Prefills, Tender, AnswerSetBuilder, OfferEvaluation } from '@dims/components';
import {
  qualityRatioPercentage,
  calculateBPQRatioScore,
  calculatePrice,
  calculatePriceScore,
  calculateTCOPrice,
  calculateTotalScore,
  getExtendedPriceSpan,
  getCalculator,
} from '@/services/utils5086';

import { Offer5086 } from '@/models/Offer';
import { Tender5086, TenderData5086 } from '@/models/Tender';

// eslint-disable-next-line arrow-body-style
const getOfferPrice = (offer: Offer5086, tender: Tender5086): number => {
  return calculatePrice(offer, tender);
};

// eslint-disable-next-line arrow-body-style
const offerIsUnconditional = (offer: Offer, tender: Tender5086): boolean => {
  return offer.status === 'Unconditional'
      || (offer.isUnconditional ?? false)
      || getCalculator().isScoreBelowConditionalThreshold_BackendCalculation(offer)
      || getCalculator().isScoreBelowConditionalThreshold_FrontendCalculation(offer, tender);
};

export default {
  applyTerminationLetterAgreementSpecific(
    answers: AnswerSetBuilder,
    offer: Offer5086,
    tender: Tender5086,
    winningOffer: Offer | undefined,
    conditionalOffers: Offer[],
    isUnconditional: boolean,
    offersEvaluations: OfferEvaluation[],
  ) {
    const { tenderData } = offer;

    if (winningOffer) {
      // Winning offer name
      console.info(
        'winner',
        winningOffer.sender.companyName,
        getOfferPrice(winningOffer, tender),
      );

      // Winning offers price
      answers.addCurrency('91a4ddea-b512-4d57-9ed7-92a790fc1d61', getOfferPrice(winningOffer, tender));

      // Winner's scores
      if (tenderData.awardCriteriaType === 'BPQRatio') {
        const winningOfferEvaluation = offersEvaluations.find((oe) => oe.offerId === winningOffer.id);

        // Price score
        answers.addNumber2Decimals(
          '78ad818d-1eb7-4d14-ae29-d77ce0d7df93',
          calculatePriceScore(conditionalOffers, winningOffer, tender),
        );
        // APV score
        answers.addNumber2Decimals(
          '33a23417-fdf8-4b6b-a921-06f4424ce6f9',
          calculateBPQRatioScore(conditionalOffers, tender, winningOfferEvaluation),
        );
        // Total score
        answers.addNumber2Decimals(
          '1cb29e8a-93b4-4514-8454-4fd8f3cf18b6',
          calculateTotalScore(
            conditionalOffers,
            winningOffer,
            tender,
            winningOfferEvaluation,
          ),
        );
      }
    }

    answers.addCurrency('6298432b-ebc0-4f9e-ae2d-fce9ec956565', getOfferPrice(offer, tender));

    const apvCriteria = (id: string) => {
      answers.addCheck('e3f7fe81-f82a-4e83-a07d-1dc661fb2554', id);
    };
    const apvCriteriaWeight = (questionId: string, value: number) => {
      answers.addNumber(questionId, value);
    };

    // Best price quality ratio, APV
    if (tender.awardCriteriaType === 'BPQRatio') {
      const td = tender.data;
      if (td.bpqCriteriaDrivingCharacteristics) {
        apvCriteria('0732b01b-c54f-46bd-b97d-a7ae3c7ed3ed');
        apvCriteriaWeight(
          '87c1dca9-8eaf-499a-b34b-529acd0ff226',
          td.bpqCriteriaDrivingCharacteristics,
        );
      }
      if (td.bpqCriteriaDriversSeatAdjustment) {
        apvCriteria('005f2319-3bff-46d0-bddb-c3e7ff0c8ffc');
        apvCriteriaWeight(
          'a4302dd5-34ff-4c03-866c-425889b2119e',
          td.bpqCriteriaDriversSeatAdjustment,
        );
      }
      if (td.bpqCriteriaEntryExitConditions) {
        apvCriteria('d3c34b20-bd95-4055-817a-78625ce79558');
        apvCriteriaWeight(
          '0badeeaa-45cc-41df-b028-5cf742d55c32',
          td.bpqCriteriaEntryExitConditions,
        );
      }
      if (td.bpqCriteriaView) {
        apvCriteria('0caa8070-231e-469d-be46-390ea1822d8b');
        apvCriteriaWeight(
          '82aa29a3-99a0-46c3-b503-250cf635ee8a',
          td.bpqCriteriaView,
        );
      }
      if (td.bpqCriteriaCabinNoise) {
        apvCriteria('1b29af14-a1ba-4832-a697-3395e235c678');
        apvCriteriaWeight(
          '5fd5ee6c-a066-4359-9e6d-e0cc988b4aec',
          td.bpqCriteriaCabinNoise,
        );
      }
      if (td.bpqCriteriaInstrumentation) {
        apvCriteria('2e529ef0-51f5-42e0-b76a-c88c3fa3be07');
        apvCriteriaWeight(
          '57cae357-84dd-4458-97e2-1e91e0f1b847',
          td.bpqCriteriaInstrumentation,
        );
      }
      if (td.bpqCriteriaComfort) {
        apvCriteria('935bf634-1343-48b7-a625-e03d92284188');
        apvCriteriaWeight(
          'e5bc2262-dae6-4624-ad03-8afa43f58b32',
          td.bpqCriteriaComfort,
        );
      }

      const offerEvaluation = offersEvaluations.find((oe) => oe.offerId === offer.id);

      // Price score
      answers.addNumber2Decimals(
        '3809447e-bc74-44b0-9d75-e45a4bf8d082',
        calculatePriceScore(conditionalOffers, offer, tender),
      );
      // APV score
      answers.addNumber2Decimals(
        'b41cc488-c4e0-4542-a094-caee2f3971e9',
        calculateBPQRatioScore(conditionalOffers, tender, offerEvaluation),
      );
      // Total score
      answers.addNumber2Decimals(
        '3754bc3d-042c-4703-83ab-29dc3da3e703',
        calculateTotalScore(conditionalOffers, offer, tender, offerEvaluation),
      );

      // APV custom percentage for cost criteria
      if (String(td.bpqRatioPercentage) === 'Anden procentdel' && td.bpqRatioCustomPercentage) {
        answers.addNumber('e3218b8e-d1ff-47ff-921c-a7b4a9d4cc95', (100 - td.bpqRatioCustomPercentage));
      }

      // Extended price span?
      const prices = conditionalOffers.map((o) => calculateTCOPrice(o, tender));
      const lowestPrice = Math.min(...prices);
      const answerId = getExtendedPriceSpan(prices, lowestPrice, tender)
        ? '09409e8c-7c04-42b6-a027-4d0b1cc20ae8'
        : '86719fa7-5c50-4402-8b7d-cc063aaa101c';
      answers.addCheck('391e26f7-46dd-4fef-ae55-5b19be2785ef', answerId);
      answers.addNumber('5f0aacf2-cca1-42d0-9f9c-3bc68383ef32', td.bpqExtendedCostsPercentage ?? 0);
    } else {
      // TCO
      answers.add('5f0aacf2-cca1-42d0-9f9c-3bc68383ef32', '-');
    }
  },

  applyEvaluationReportAgreementSpecific(
    answers: AnswerSetBuilder,
    tender: Tender,
    conditionalOffers: Offer[],
    unconditionalOffers: Offer[],
    cvrNumbers: Record<string, string>,
    offersEvaluations: OfferEvaluation[],
  ) {
    const td = tender.data as TenderData5086;

    // Sets count for the offer-repeater
    // (This is a special-case hidden question)
    answers.addNumber(
      'e8f1535d-cfc8-4d16-adad-ab1c1493a39f',
      conditionalOffers.length + unconditionalOffers.length,
    );

    // Award type: Best price / Quality
    if (tender.awardCriteriaType === 'BPQRatio') {
      let apvDeliveryTime = td.deliveryTimeAPVTime ?? '';
      if (td.deliveryTimeAPVTimeFixed?.startsWith('kl.') === true) {
        apvDeliveryTime = td.deliveryTimeAPVTimeFixed.substring(2).trim();
      }
      answers.add('77aeb8a1-3831-4b48-aed9-450ede7ac461', apvDeliveryTime);

      const weightQuality = qualityRatioPercentage(tender);
      const weightPrice = 100 - weightQuality;
      answers.addNumber('568a6827-2afe-4ef5-9363-f31daf4c17f0', weightQuality);
      answers.addNumber('5b20e131-e518-4800-833c-0b0b1cac088d', weightPrice);
      answers.add('e3c3370c-b6da-4ea1-9a7d-0b419c697fd2', td.bpqCostsPercentage?.toString() ?? '');

      // Is price span extended or not?
      const prices = conditionalOffers.map((o) => calculateTCOPrice(o, tender));
      const lowestPrice = Math.min(...prices);
      const extendedPriceSpanIsUsed = typeof getExtendedPriceSpan(prices, lowestPrice, tender) !== 'undefined';
      answers.addCheck(
        'c865e39f-6fda-40ec-921f-343af9d805cb',
        extendedPriceSpanIsUsed
          ? '131903fa-893c-41df-b794-13b910b5888e'
          : '600855b9-95d3-4c1b-b398-f60dd00b1186',
      );

      if (td.bpqExtendedCostsPercentage) {
        answers.addNumber('34b62985-5da2-4b76-8950-050d9e918094', td.bpqExtendedCostsPercentage);
      }
      const addApvCriteria = (answerId: string) => {
        answers.addCheck('97e968cf-aa4b-4db5-bcf1-c9b09185ba82', answerId);
      };
      (td.bpqCriteria ?? []).forEach((c) => {
        switch (c) {
          case 'DrivingCharacteristics':
            addApvCriteria('ea3f1f38-b21c-48fe-8f94-f4ba5663ac55');
            break;
          case 'DriversSeatAdjustment':
            addApvCriteria('44ef2d98-a6ab-46b8-89f2-7472c4bf8a7e');
            break;
          case 'EntryExitConditions':
            addApvCriteria('bb34ca26-e138-49f3-a21e-57e3793f0cb2');
            break;
          case 'View':
            addApvCriteria('fe036f0c-4572-4ee4-b7cd-4065aa99e6e0');
            break;
          case 'CabinNoise':
            addApvCriteria('f9086795-1c8a-4da7-8945-1c536da813d0');
            break;
          case 'Instrumentation':
            addApvCriteria('de1ee433-8b09-4405-be77-b8fded4341d8');
            break;
          case 'Comfort':
            addApvCriteria('3b1e704a-9b5e-4ab9-ad1f-0d6dcca4c0c5');
            break;
          default:
            throw new Error(`Cannot add unknown BPQ criteria ${String(c)}`);
        }
      });
    }

    // Indkomne tilbud (repeater fields)
    const companyNameQuestionId = '30bfb2f6-2158-49e9-b811-c7a9bef8880c';
    const companyCvrQuestionId = 'b8dd3ff7-cd5b-4c84-b3b7-2f6fe60ee16b';
    const conditionalQuestionId = '34202a02-e5d1-430b-a158-a0bb94d1c39b';
    const conditionalTrueAnswerId = '6a4d2959-9539-40df-9287-fcf0e9af95d9';
    const conditionalFalseAnswerId = 'f5024533-1cef-426d-a0b0-c7b882757c33';
    const tcoPriceQuestionId = '034e24c1-5538-4128-a9ef-5ad35a2d7d96';
    const tcoPointQuesstionId = '7a940143-3348-4897-8a71-b32c4e12fe23';
    const apvScoreQuestionId = '7a0ed686-0060-41da-b4c3-c3383b709a76';
    const totalScoreQuestionId = 'a2089ef5-2dca-4e19-9d19-dfc208ca4315';

    const espdDocumentationApprovedQuestionId = '808fed60-eb55-4703-8a94-6d922fac771b';
    const espdDocumentationApprovedTrueAnswerId = '7a5f9f96-61a0-43b5-ad22-b4b22111084e';
    const espdDocumentationApprovedFalseAnswerId = 'b00d6794-0d40-471e-bb3e-9090f19736d0';

    // All offers
    const allOffers: Offer[] = [];
    allOffers.push(...conditionalOffers, ...unconditionalOffers);
    allOffers.forEach((o: Offer, index: number) => {
      const offerEvaluation = offersEvaluations.find((oe) => oe.offerId === o.id);

      if (index === 0) {
        answers.add(companyNameQuestionId, o.sender.companyName);
        answers.add(companyCvrQuestionId, cvrNumbers[o.sender.organizationNumber] ?? '');
        answers.addCheck(
          conditionalQuestionId,
          offerIsUnconditional(o, tender)
            ? conditionalFalseAnswerId : conditionalTrueAnswerId,
        );
        answers.addCurrency(
          tcoPriceQuestionId,
          calculateTCOPrice(o, tender),
        );
        answers.addNumber2Decimals(
          tcoPointQuesstionId,
          calculatePriceScore(conditionalOffers, o, tender),
        );
        answers.addNumber2Decimals(
          apvScoreQuestionId,
          calculateBPQRatioScore(conditionalOffers, tender, offerEvaluation),
        );
        answers.addNumber2Decimals(
          totalScoreQuestionId,
          calculateTotalScore(conditionalOffers, o, tender, offerEvaluation),
        );
        answers.addCheck(
          espdDocumentationApprovedQuestionId,
          o.documentationApproved
            ? espdDocumentationApprovedTrueAnswerId : espdDocumentationApprovedFalseAnswerId,
        );
      } else {
        answers.add(`${companyNameQuestionId}_${index}`, o.sender.companyName);
        answers.add(`${companyCvrQuestionId}_${index}`, cvrNumbers[o.sender.organizationNumber] ?? '');
        answers.addCheck(
          `${conditionalQuestionId}_${index}`,
          offerIsUnconditional(o, tender)
            ? `${conditionalFalseAnswerId}_${index}` : `${conditionalTrueAnswerId}_${index}`,
        );
        answers.addCurrency(
          `${tcoPriceQuestionId}_${index}`,
          calculateTCOPrice(o, tender),
        );
        answers.addNumber2Decimals(
          `${tcoPointQuesstionId}_${index}`,
          calculatePriceScore(conditionalOffers, o, tender),
        );
        answers.addNumber2Decimals(
          `${apvScoreQuestionId}_${index}`,
          calculateBPQRatioScore(conditionalOffers, tender, offerEvaluation),
        );
        answers.addNumber2Decimals(
          `${totalScoreQuestionId}_${index}`,
          calculateTotalScore(conditionalOffers, o, tender, offerEvaluation),
        );
      }
    });
    const winningOffer = conditionalOffers[0];
    answers.addCheck(
      espdDocumentationApprovedQuestionId,
      winningOffer?.documentationApproved
        ? espdDocumentationApprovedTrueAnswerId : espdDocumentationApprovedFalseAnswerId,
    );
  },
} satisfies Prefills;
