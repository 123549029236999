// Categories from Færdselsstyrelsen,
// <https://fstyr.dk/da/Krav-til-koeretoejer/Regler-om-energi-og-miljoeegenskaber/Viden-om-drivmidler>

enum PropellantEnum {
  GASOLINE = 'Gasoline',
  DIESEL = 'Diesel',
  BIOGAS = 'Biogas',
  ELECTRICITY = 'Electricity',
  HYDROGEN = 'Hydrogen',
  PLUGIN_HYBRID = 'PluginHybrid',
  GASOLINE_HYBRID = 'GasolineHybrid',
  DIESEL_HYBRID = 'DieselHybrid',
}

// OTHER at the end on purpose
export const propellantTypes = [
  PropellantEnum.GASOLINE,
  PropellantEnum.DIESEL,
  PropellantEnum.BIOGAS,
  PropellantEnum.ELECTRICITY,
  PropellantEnum.HYDROGEN,
  PropellantEnum.PLUGIN_HYBRID,
  PropellantEnum.GASOLINE_HYBRID,
  PropellantEnum.DIESEL_HYBRID,
];

const icePropellants: PropellantEnum[] = [
  PropellantEnum.GASOLINE,
  PropellantEnum.GASOLINE_HYBRID,
  PropellantEnum.DIESEL,
  PropellantEnum.DIESEL_HYBRID,
  PropellantEnum.PLUGIN_HYBRID,
  PropellantEnum.BIOGAS,
];

const electricPropellants: PropellantEnum[] = [
  PropellantEnum.ELECTRICITY,
  PropellantEnum.PLUGIN_HYBRID,
];

export const propellantTypeLabels = (type?: PropellantEnum): string => {
  switch (type) {
    case undefined:
      return '';
    case PropellantEnum.GASOLINE:
      return 'Benzin';
    case PropellantEnum.DIESEL:
      return 'Diesel';
    case PropellantEnum.BIOGAS:
      return 'Biogas';
    case PropellantEnum.ELECTRICITY:
      return 'El';
    case PropellantEnum.HYDROGEN:
      return 'Brint';
    case PropellantEnum.PLUGIN_HYBRID:
      return 'Plugin Hybrid';
    case PropellantEnum.GASOLINE_HYBRID:
      return 'Benzin-Hybrid';
    case PropellantEnum.DIESEL_HYBRID:
      return 'Diesel-Hybrid';
    default:
      return 'Unknown propellant type';
  }
};

export const propellantTypeFromLabel = (
  label: string,
): PropellantEnum | undefined => propellantTypes
  .find((pt) => propellantTypeLabels(pt) === label);

export const isPropellantICE = (
  propellant: PropellantEnum,
): boolean => icePropellants.includes(propellant);

export const isPropellantElectric = (
  propellant: PropellantEnum,
): boolean => electricPropellants.includes(propellant);

export default PropellantEnum;
