<template>
  <v-container fluid>
    <HeaderSection header="FAQ" subHeader="" />
    <v-expansion-panels
      class="mt-4"
      variant="accordion"
      v-model="panel"
      multiple
    >
      <v-expansion-panel>
        <v-expansion-panel-title class="bg-primary px-4 py-6">
          <template #actions>
            <v-icon color="white"> mdi-chevron-down </v-icon>
          </template>
          <div>
            Hvordan kan jeg købe grønne køretøjer på aftalen?
          </div>
        </v-expansion-panel-title>
        <v-expansion-panel-text class="bg-canvas text-black px-4 pt-4">
          <p>
            Du har mulighed for at kravspecificere køretøjet som et grønt
            køretøj, fx med drivmidler som el, brint, gas, hybrid mv. Og du kan
            altid kravspecificere særlige krav til miljø fx POGI-krav.
          </p>
          <p>
            Du skal altså benytte kravspecifikationen og ikke
            tildelingskriterierne til at sikre dine grønne indøb. Der findes to
            tildelingskriterier i det dynamiske indkøbssystem, henholdsvis
            ”Omkostninger” og ”Bedste forhold mellem pris og kvalitet”. For
            tildelingskriteriet omkostninger evalueres der alene på prisen.
            Tildelingskriteriet ”Bedste forhold mellem pris og kvalitet” er
            underkriterierne kvalitet (APV) og pris.
          </p>
        </v-expansion-panel-text>
      </v-expansion-panel>
      <v-expansion-panel class="mt-8">
        <v-expansion-panel-title class="bg-primary px-4 py-6">
          <template #actions>
            <v-icon color="white"> mdi-chevron-down </v-icon>
          </template>
          <div>
            Skal jeg bruge SKI’s dokumenter?
          </div>
        </v-expansion-panel-title>
        <v-divider class="ma-0" />
        <v-expansion-panel-text class="bg-canvas text-black px-4 pt-4">
          <p>
            Det står dig helt frit for at bruge dine egne dokumenter til at
            gennemføre dit konkrete indkøb. SKI’s dokumenter er udelukkende et
            tilbud til dig. Bruger du SKI’s dokument til kravspecifikation
            kommer du igennem alle relevante punkter, så du sikrer dig, at du
            kommer omkring hele indkøbet.
          </p>
        </v-expansion-panel-text>
      </v-expansion-panel>
      <v-expansion-panel class="mt-8">
        <v-expansion-panel-title class="bg-primary px-4 py-6">
          <template #actions>
            <v-icon color="white"> mdi-chevron-down </v-icon>
          </template>
          <div>
            Hvad nu hvis min foretrukne leverandør ikke er på aftalen?
          </div>
        </v-expansion-panel-title>
        <v-divider class="ma-0" />
        <v-expansion-panel-text class="bg-canvas text-black px-4 pt-4">
          <p>
            Alle leverandører kan søge om at blive optaget på aftalen. Der
            ligger vejledninger og videoer, der viser, hvordan man søger om at
            komme på aftalen. Leverandøren skal opfylde nogle økonomiske og
            tekniske krav, som de kan læse mere om i vejledningen.
          </p>
        </v-expansion-panel-text>
      </v-expansion-panel>
    </v-expansion-panels>
  </v-container>
</template>

<script setup lang="ts">
import { ref } from 'vue';
import { HeaderSection } from '@dims/components';

const panel = ref([0, 1, 2]);

</script>

<style scoped>

li {
  list-style: square;
}

.v-expansion-panel-title {
  color: white;
  font-size: 1.2rem;
}

</style>
