import { AwardCriteriaType, labelForRegion, Region, regionLabels, Supplier } from '@dims/components';
import { Offer5086 } from '@/models/Offer';
import { TenderData5086, TenderSummary5086 } from '@/models/Tender';
import VehicleTypesEnum, { vehicleTypeLabels } from '@/models/VehicleTypes';
import { awardCriteriaType } from '@/services';

export default class FilterConfiguration {
  regionFilter = '';
  vehicleTypeFilter = '';
  awardTypeFilter = '';

  vehicleTypeLabels = [
    VehicleTypesEnum.LEASING,
    VehicleTypesEnum.OTHER_VEHICLE,
    VehicleTypesEnum.PEOPLE_MOVER,
    VehicleTypesEnum.UTILITY_VEHICLE,
  ].map((t) => vehicleTypeLabels(t));

  awardTypeLabels = (['TCO', 'BPQRatio'] as const).map((a) => awardCriteriaType.getLabel(a));

  properties = [
    { label: 'Region', propertyName: 'regionFilter', options: regionLabels },
    { label: 'Biltype', propertyName: 'vehicleTypeFilter', options: this.vehicleTypeLabels },
    { label: 'Tildelingskriterie', propertyName: 'awardTypeFilter', options: this.awardTypeLabels },
  ];

  filterTender(tender: TenderSummary5086) {
    const { region, vehicleType } = tender.data;
    return this.filter(tender.awardCriteriaType, region, vehicleType);
  }
  filterOffer(offer: Offer5086) {
    const { region, vehicleType } = offer.tenderData;
    return this.filter(offer.tenderData.awardCriteriaType, region, vehicleType);
  }
  private filter(awardType?: AwardCriteriaType | null, region?: Region | null, vehicleType?: VehicleTypesEnum | null) {
    return Boolean((
      this.regionFilter === ''
            || (region && labelForRegion(region) === this.regionFilter))
        && (this.vehicleTypeFilter === ''
            || (vehicleType && vehicleTypeLabels(vehicleType) === this.vehicleTypeFilter))
        && (this.awardTypeFilter === ''
          || (awardType && awardCriteriaType.getLabel(awardType) === this.awardTypeFilter)));
  }
  filterLot(l: { lotNo:string, title: string }) {
    return (
      (this.regionFilter === ''
        || l.title.startsWith(this.regionFilter))
      && (this.awardTypeFilter === ''
          || l.title.endsWith(this.awardTypeFilter))
      && (this.vehicleTypeFilter === ''
          || l.title.includes(this.vehicleTypeFilter))
    );
  }
  filterSupplier(_tenderData: TenderData5086, _supplier: Supplier) {
    return true;
  }
}
