<template>
  <div>
    <LoadingSpinner :visible="isLoading" loadingMessage="Henter tilbud" />
    <div v-if="!isLoading">
      <v-card v-if="tender" class="pa-3" color="#fff9ef">
        <PrintableRegion
          ref="printableRegion"
          :fileName="`Tilbud-${tender.friendlyId}.pdf`"
          :header="header"
        >
          <v-card-text>
            <div>{{ tender.user.companyName }}</div>
            <div>{{ tender.description }}</div>
            <div>{{ tender.friendlyId }}</div>
            <h1 class="font-weight-bold">Tilbud</h1>
            <h3 class="mt-2">
              <p
                v-if="offer.sender.companyName && offerCvr"
                class="mb-1"
              >
                {{
                  `${offer.sender.companyName}, CVR. ${offerCvr}`
                }}
              </p>
              <p
                v-if="offerPublishedDate && offer.sender.fullName"
                class="mb-1"
              >
                {{
                  `Tilbud indsendt d. ${offerPublishedDate} af ${offer.sender.fullName}`
                }}
              </p>
              <br />
              <p :class="{ 'text-error': !offer.specificationAccepted }">
                <span>Tilbudsgiver har angivet, at tilbuddet </span>
                <span v-if="!offer.specificationAccepted" class="font-weight-bold">ikke</span>
                <span> opfylder samtlige krav i kravspecifikationen </span>
                <span v-if="offer.specificationAccepted">(konditionsmæssigt).</span>
                <span v-else>(ukonditionsmæssigt).</span>
              </p>
            </h3>

            <v-divider />
            <h2 class="mt-6 mb-0 document-drafter-headline">
              Generelt
            </h2>
            <div v-if="offer.data.brand" class="mb-2 dont-break">
              <div>
                <strong>Mærke:</strong>
              </div>
              <div>
                {{ offer.data.brand }}
              </div>
            </div>
            <div v-if="offer.data.model" class="mb-2 dont-break">
              <div>
                <strong>Model:</strong>
              </div>
              <div>
                {{ offer.data.model }}
              </div>
            </div>
            <div v-if="offer.data.variant" class="mb-2 dont-break">
              <div>
                <strong>Variant:</strong>
              </div>
              <div>
                {{ offer.data.variant }}
              </div>
            </div>
            <div v-if="offer.data.equipmentLine" class="mb-2 dont-break">
              <div>
                <strong>Udstyrslinje:</strong>
              </div>
              <div>
                {{ offer.data.equipmentLine }}
              </div>
            </div>
            <div v-if="enginePerformanceSection">
              <v-divider />
              <h2 class="mt-6 mb-0 document-drafter-headline">
                Motor og præstation
              </h2>
            </div>
            <div v-if="offer.data.engineSize" class="mb-2 dont-break">
              <div>
                <strong>Motorstørrelse:</strong>
              </div>
              <div>
                {{ offer.data.engineSize }}
              </div>
            </div>
            <div v-if="offer.data.power" class="mb-2 dont-break">
              <div>
                <strong>HK/Moment:</strong>
              </div>
              <div>
                {{ offer.data.power }}
              </div>
            </div>
            <div v-if="offer.data.propellantType" class="mb-2 dont-break">
              <div>
                <strong>Brændstofstype:</strong>
              </div>
              <div>
                {{ propellantTypeLabels(offer.data.propellantType) }}
              </div>
            </div>
            <div v-if="offer.data.consumptionICE" class="mb-2 dont-break">
              <div>
                <strong>Brændstofforbrug (km/l):</strong>
              </div>
              <div>
                {{ offer.data.consumptionICE }}
              </div>
            </div>
            <div v-if="offer.data.consumptionElectric" class="mb-2 dont-break">
              <div>
                <strong>Elektrisk forbrug (Wh/km):</strong>
              </div>
              <div>
                {{ offer.data.consumptionElectric }}
              </div>
            </div>
            <div v-if="offer.data.chargingStationPrice" class="mb-2 dont-break">
              <div>
                <strong>Indkøbspris for ladestandere (evt. ladebokse) til El-/Plugin
                  Hybrid-biler - ekskl. installationsomkostninger. Pr.
                  køretøj:</strong>
              </div>
              <div>
                {{ offer.data.chargingStationPrice }}
              </div>
            </div>
            <div v-if="offer.data.chargingSubscription" class="mb-2 dont-break">
              <div>
                <strong>Opladningsabonnement kr./år pr. køretøj:</strong>
              </div>
              <div>
                {{ offer.data.chargingSubscription }}
              </div>
            </div>
            <div v-if="offer.data.vehicleClassType" class="mb-2 dont-break">
              <div>
                <strong>Køretøjsklasse:</strong>
              </div>
              <div>
                {{ vehicleClassTypeLabels(offer.data.vehicleClassType) }}
              </div>
            </div>
            <div v-if="offer.data.emission" class="mb-2 dont-break">
              <div>
                <strong>Emission (CO₂ g/km):</strong>
              </div>
              <div>
                {{ offer.data.emission }}
              </div>
            </div>
            <div v-if="bodyInternal">
              <v-divider />
              <h2 class="mt-6 mb-0 document-drafter-headline" v-if="bodyInternal">
                Karosseri, indvendigt
              </h2>
            </div>
            <h2 class="document-drafter-sub-headline" v-if="bodyInternalGeneral">
              Generelt
            </h2>
            <div v-if="offer.data.comfortDistanceSingleSeatRow" class="mb-2 dont-break">
              <div>
                <strong>Komfortmål m. en sæderække (A) - mål foran, min.
                  (gulpladebil):</strong>
              </div>
              <div>
                {{ offer.data.comfortDistanceSingleSeatRow }}
              </div>
            </div>
            <div v-if="offer.data.seatHeightFront" class="mb-2 dont-break">
              <div>
                <strong>Højde over forsæder (målt lodret midt på ubelastet sæde, med
                  dette trukket helt tilbage og med højderegulering helt i bund),
                  min.:</strong>
              </div>
              <div>
                {{ offer.data.seatHeightFront }}
              </div>
            </div>
            <div v-if="offer.data.lengthDriversSeatToTrunk" class="mb-2 dont-break">
              <div>
                <strong>Længde fra indvendig side af lukket bagklap til bagside af
                  forsæde (målt i bunden), min.:</strong>
              </div>
              <div>
                {{ offer.data.lengthDriversSeatToTrunk }}
              </div>
            </div>
            <div v-if="offer.data.isoFixBackSeat" class="mb-2 dont-break">
              <div>
                <strong>Isofixbeslag på bagsæde:</strong>
              </div>
              <div>
                {{ offer.data.isoFixBackSeat }}
              </div>
            </div>
            <div v-if="offer.data.comfortDistanceDoubleSeatRow" class="mb-2 dont-break">
              <div>
                <strong>Komfortmål for de forreste to sæderækker (A+B) - mål foran,
                  min.:</strong>
              </div>
              <div>
                {{ offer.data.comfortDistanceDoubleSeatRow }}
              </div>
            </div>
            <div v-if="offer.data.seatHeightBack" class="mb-2 dont-break">
              <div>
                <strong>Højde over bagsæde (målt lodret midt på ubelastet sæde),
                  min.:</strong>
              </div>
              <div>
                {{ offer.data.seatHeightBack }}
              </div>
            </div>
            <div v-if="offer.data.heightFloorToCeiling" class="mb-2 dont-break">
              <div>
                <strong>Mål fra gulv til loft i passagerkabine, min.:</strong>
              </div>
              <div>
                {{ offer.data.heightFloorToCeiling }}
              </div>
            </div>
            <h2 class="document-drafter-sub-headline" v-if="bodyInternalTrunk">
              Bagagerum / varerum / godsrum / lastrum
            </h2>
            <div v-if="offer.data.lengthBackPanelBackSeat" class="mb-2 dont-break">
              <div>
                <strong>Længde fra bagpanel til bagsædekant i bunden, min.:</strong>
              </div>
              <div>
                {{ offer.data.lengthBackPanelBackSeat }}
              </div>
            </div>
            <div v-if="offer.data.lengthCargoSpace" class="mb-2 dont-break">
              <div>
                <strong>Længde af varerum (måles i bunden fra bagdør til adskillelse
                  mellem varerum og passagerkabine):</strong>
              </div>
              <div>
                {{ offer.data.lengthCargoSpace }}
              </div>
            </div>
            <div v-if="offer.data.volumeCargoSpace" class="mb-2 dont-break">
              <div>
                <strong>Varerumsvolumen (VDA):</strong>
              </div>
              <div>
                {{ offer.data.volumeCargoSpace }}
              </div>
            </div>
            <div v-if="offer.data.widthBetweenWheelArches" class="mb-2 dont-break">
              <div>
                <strong>Bredde mellem hjulkasser:</strong>
              </div>
              <div>
                {{ offer.data.widthBetweenWheelArches }}
              </div>
            </div>
            <div v-if="offer.data.heightBottomToCeiling" class="mb-2 dont-break">
              <div>
                <strong>Højde fra bund til loft på højeste sted:</strong>
              </div>
              <div>
                {{ offer.data.heightBottomToCeiling }}
              </div>
            </div>
            <div v-if="offer.data.volumeTrunk" class="mb-2 dont-break">
              <div>
                <strong>Bagagerumsvolumen minimum (VDA):</strong>
              </div>
              <div>
                {{ offer.data.volumeTrunk }}
              </div>
            </div>
            <h2 class="document-drafter-sub-headline" v-if="bodyInternalTruckBed">
              Lad
            </h2>
            <div v-if="offer.data.weightTruckBedTotal" class="mb-2 dont-break">
              <div>
                <strong>Totalvægt:</strong>
              </div>
              <div>
                {{ offer.data.weightTruckBedTotal }}
              </div>
            </div>
            <div v-if="offer.data.truckBedSize" class="mb-2 dont-break">
              <div>
                <strong>Størrelse på lad:</strong>
              </div>
              <div>
                {{ offer.data.truckBedSize }}
              </div>
            </div>
            <div v-if="bodyExternal">
              <v-divider />
              <h2 class="mt-6 mb-0 document-drafter-headline">
                Karosseri, udvendigt
              </h2>
            </div>
            <h2 class="document-drafter-sub-headline" v-if="bodyExternalDimensions">
              Dimensioner
            </h2>
            <div v-if="offer.data.lengthTotal" class="mb-2 dont-break">
              <div>
                <strong>Totallængde, maks. jf. producentens specifikationer:</strong>
              </div>
              <div>
                {{ offer.data.lengthTotal }}
              </div>
            </div>
            <div v-if="offer.data.heightToFloor" class="mb-2 dont-break">
              <div>
                <strong>Indstigningshøjde, målt fra jorden til det nederste punkt i
                  døren, min.:</strong>
              </div>
              <div>
                {{ offer.data.heightToFloor }}
              </div>
            </div>
            <div v-if="offer.data.heightTotal" class="mb-2 dont-break">
              <div>
                <strong>Højde målt fra jorden til det højeste punkt på køretøjet,
                  maks.:</strong>
              </div>
              <div>
                {{ offer.data.heightTotal }}
              </div>
            </div>
            <h2 class="document-drafter-sub-headline" v-if="bodyExternalWeights">
              Vægte
            </h2>
            <div v-if="offer.data.weightTotal" class="mb-2 dont-break">
              <div>
                <strong>Totalvægt, maks. i kg:</strong>
              </div>
              <div>
                {{ offer.data.weightTotal }}
              </div>
            </div>
            <div v-if="offer.data.weightCargo" class="mb-2 dont-break">
              <div>
                <strong>Nyttelast (forskellen mellem tilladt totalvægt og
                  køreklar/tjenestevægt jf. detailforskrifter for køretøjer), min.
                  :</strong>
              </div>
              <div>
                {{ offer.data.weightCargo }}
              </div>
            </div>
            <div v-if="environmentalRequirements">
              <v-divider />
              <h2 class="mt-6 mb-0 document-drafter-headline">
                Miljøkrav
              </h2>
            </div>
            <div v-if="offer.data.energyClass" class="mb-2 dont-break">
              <div>
                <strong>Energiklasse:</strong>
              </div>
              <div>
                {{ offer.data.energyClass }}
              </div>
            </div>
            <div v-if="showService">
              <v-divider />
              <h2 class="mt-6 mb-0 document-drafter-headline">
                Serviceydelser i TCO-perioden
              </h2>
            </div>
            <div v-if="hasValue(offer.data.priceService)" class="mb-2 dont-break">
              <div>
                <strong>Serviceaftale (pr. bil):</strong>
              </div>
              <div>
                {{ toCurrencyFormat(offer.data.priceService) }}
              </div>
            </div>
            <div v-if="hasValue(offer.data.priceRepairs)" class="mb-2 dont-break">
              <div>
                <strong>Reparationsaftale (pr. bil):</strong>
              </div>
              <div>
                {{ toCurrencyFormat(offer.data.priceRepairs) }}
              </div>
            </div>
            <div
              v-if="hasValue(offer.data.priceCollectDeliverService)"
              class="mb-2 dont-break"
            >
              <div>
                <strong>Hente/bringe-service (pr. bil):</strong>
              </div>
              <div>
                {{ toCurrencyFormat(offer.data.priceCollectDeliverService) }}
              </div>
            </div>
            <div v-if="hasValue(offer.data.priceServicePeriod)" class="mb-2 dont-break">
              <div>
                <strong>Garantiperiode (pr. bil):</strong>
              </div>
              <div>
                {{ toCurrencyFormat(offer.data.priceServicePeriod) }}
              </div>
            </div>
            <div v-if="hasValue(offer.data.priceTireAgreement)" class="mb-2 dont-break">
              <div>
                <strong>Dækaftale (pr. bil):</strong>
              </div>
              <div>
                {{ toCurrencyFormat(offer.data.priceTireAgreement) }}
              </div>
            </div>
            <div v-if="hasValue(offer.data.chargingSubscription)" class="mb-2 dont-break">
              <div>
                <strong>Opladningsabonnement kr./år pr. køretøj:</strong>
              </div>
              <div>
                {{ toCurrencyFormat(offer.data.chargingSubscription) }}
              </div>
            </div>
            <div v-if="enginePerformanceSection">
              <v-divider />
              <h2 class="mt-6 mb-0 document-drafter-headline">
                Pris
              </h2>
            </div>
            <div v-if="offer.data.priceTotal" class="mb-2 dont-break">
              <div>
                <strong>Grundpris (pr. bil):</strong>
              </div>
              <div>
                {{ toCurrencyFormat(offer.data.priceTotal) }}
              </div>
            </div>
            <div v-if="hasValue(offer.data.registrationTax)" class="mb-2 dont-break">
              <div>
                <strong>Registreringsafgift (pr. bil):</strong>
              </div>
              <div>
                {{ toCurrencyFormat(offer.data.registrationTax ?? undefined) }}
              </div>
            </div>
            <div v-if="offer.data.priceDelivery" class="mb-2 dont-break">
              <div>
                <strong>Leveringsomkostninger (pr. bil):</strong>
              </div>
              <div>
                {{ toCurrencyFormat(offer.data.priceDelivery) }}
              </div>
            </div>
            <div
              v-if="hasValue(offer.data.priceDeliveryAtCustomer)"
              class="mb-2 dont-break"
            >
              <div>
                <strong>Levering hos ordregiver (pr. bil):</strong>
              </div>
              <div>
                {{ toCurrencyFormat(offer.data.priceDeliveryAtCustomer ?? undefined) }}
              </div>
            </div>
            <div v-if="offer.data.greenOwnerTax" class="mb-2 dont-break">
              <div>
                <strong>Grøn ejerafgift (pr. bil):</strong>
              </div>
              <div>
                {{ toCurrencyFormat(offer.data.greenOwnerTax) }}
              </div>
            </div>
            <div v-if="offer.data.priceVehicleModificationFixed" class="mb-2 dont-break">
              <div>
                <strong>Opbygning (pr. bil):</strong>
              </div>
              <div>
                {{ toCurrencyFormat(priceModificationPerVehicle(offer, tender)) }}
                {{ priceModificationDescription }}
              </div>
            </div>
            <div v-if="offer.data.priceBuyback" class="mb-2 dont-break">
              <div>
                <strong>Tilbagekøbspris (pr. bil):</strong>
              </div>
              <div>
                {{ toCurrencyFormat(offer.data.priceBuyback) }}
              </div>
            </div>
            <div v-if="artifacts && artifacts.length > 0">
              <v-divider />
              <h2 class="mt-6 mb-0 document-drafter-headline">
                Bilag
              </h2>
              <div>
                <div
                  v-for="artifact in artifacts"
                  :key="artifact.templateArtifactKey"
                  class="mb-2 dont-break"
                >
                  <DownloadArtifact
                    :tender="tender"
                    :offer="offer"
                    :templateKey="artifact.templateKey"
                    :templateArtifactKey="artifact.templateArtifactKey"
                  />
                </div>
              </div>
            </div>
            <InvoiceExample
              v-if="!usesOwnDocumentsSpecification"
              :tender="tender"
              :offer="offer"
              :description="`Med priserne ovenfor kan du her se et eksempel på en faktura fra
          ${offer.sender.companyName}:`"
            />
            <TcoCalculationViewer v-if="!usesOwnDocumentsSpecification" :tender="tender" :offer="offer" />
          </v-card-text>
        </PrintableRegion>
      </v-card>
    </div>
    <LoadingSpinner :visible="isDownloading" />
  </div>
</template>

<script setup lang="ts">
import { ref, computed, onMounted } from 'vue';
import {
  LoadingSpinner,
  DownloadArtifact,
  OfferArtifact,
  dateUtils,
  usesOwnDocumentsSpecification as tenderUsesOwnDocumentsSpecification,
  toCurrencyFormat,
  hasValue,
  useBackend,
} from '@dims/components';
import InvoiceExample from '@/components/Offers/Specification/InvoiceExample.vue';
import TcoCalculationViewer from '@/components/Offers/Specification/TcoCalculationViewer.vue';
import { propellantTypeLabels } from '@/models/PropellantTypes';
import { vehicleClassTypeLabels } from '@/models/VehicleClassTypes';
import { priceModificationPerVehicle } from '@/services/utils5086';
import PriceTypesEnum from '@/models/PriceTypes';
import { Tender5086 } from '@/models/Tender';
import { Offer5086 } from '@/models/Offer';

/* Currently client-side generated offer specifications.
  This should be moved to backend in the next version of the agreement.  */
interface PrintableRegionType { getPdf(): Promise<File | undefined> }

const { tender, offer } = defineProps<{ tender: Tender5086, offer: Offer5086 }>();
const isLoading = ref(false);
const isDownloading = ref(false);
const backend = useBackend();
const offerCvr = ref('');

onMounted(async () => {
  offerCvr.value = await backend.supplierService.fetchCvrFromOrg(tender.agreementName, offer.sender.organizationNumber) ?? '';
});

const priceModificationDescription = computed((): string => {
  if (
    tender.data.vehicleModificationPrice
      === PriceTypesEnum.HOURLY_RATE
  ) {
    return `(${
      tender.data.vehicleModificationEstimatedHours ?? 0
    } timer à ${offer.data.priceVehicleModificationFixed ?? 0} kr.)`;
  }
  if (
    tender.data.vehicleModificationPrice
      === PriceTypesEnum.FIXED_PRICE
  ) {
    return '(fast pris)';
  }
  return '';
});

const usesOwnDocumentsSpecification = computed((): boolean => tenderUsesOwnDocumentsSpecification(tender));

const enginePerformanceSection = computed(() => {
  if (
    offer.data.engineSize
      ?? offer.data.power
      ?? offer.data.propellantType
      ?? offer.data.consumptionICE
      ?? offer.data.consumptionElectric
      ?? offer.data.chargingStationPrice
      ?? offer.data.chargingSubscription
  ) {
    return true;
  }
  return false;
});

const bodyInternalGeneral = computed(() => {
  if (
    offer.data.comfortDistanceSingleSeatRow
      ?? offer.data.seatHeightFront
      ?? offer.data.lengthDriversSeatToTrunk
      ?? offer.data.isoFixBackSeat
      ?? offer.data.comfortDistanceDoubleSeatRow
      ?? offer.data.seatHeightBack
      ?? offer.data.heightFloorToCeiling
  ) {
    return true;
  }
  return false;
});

const bodyInternalTrunk = computed(() => {
  if (
    offer.data.lengthBackPanelBackSeat
      ?? offer.data.lengthCargoSpace
      ?? offer.data.volumeCargoSpace
      ?? offer.data.widthBetweenWheelArches
      ?? offer.data.heightBottomToCeiling
      ?? offer.data.volumeTrunk
  ) {
    return true;
  }
  return false;
});

const bodyInternalTruckBed = computed(() => {
  if (offer.data.weightTruckBedTotal ?? offer.data.truckBedSize) {
    return true;
  }
  return false;
});

const bodyInternal = computed(() => {
  if (
    bodyInternalGeneral.value
      || bodyInternalTrunk.value
      || bodyInternalTruckBed.value
  ) {
    return true;
  }
  return false;
});

const bodyExternalDimensions = computed(() => {
  if (
    offer.data.lengthTotal
      ?? offer.data.heightToFloor
      ?? offer.data.heightTotal
  ) {
    return true;
  }
  return false;
});

const bodyExternalWeights = computed(() => {
  if (offer.data.weightTotal ?? offer.data.weightCargo) {
    return true;
  }
  return false;
});

const bodyExternal = computed(() => {
  if (bodyExternalDimensions.value || bodyExternalWeights.value) {
    return true;
  }
  return false;
});

const environmentalRequirements = computed(() => {
  if (offer.data.energyClass) {
    return true;
  }
  return false;
});

const showService = computed((): boolean => {
  const { data } = tender;
  return (
    !!data.serviceAgreementDescriptionPeriod
      || !!data.repairmentAgreementPeriod
      || !!data.pickupDeliverServicePeriod
      || !!data.extendedWarrantyPeriod
      || !!data.tireAgreementPeriod
  );
});

const artifacts = computed((): OfferArtifact[] => (offer.artifacts ?? []).filter(
  (a) => a.templateKey === 'appendices',
));

const header = computed(() => `Tilbud på ${tender.user.companyName} | ${tender.description} | ${tender.friendlyId}`);

const offerPublishedDate = computed((): string => {
  const date = offer.publishedDate;
  return dateUtils.shortDateTimeFormat(date);
});

const printableRegion = ref<PrintableRegionType>();

async function getPdf(): Promise<File | undefined> {
  if (printableRegion.value) {
    const pdfFile = printableRegion.value.getPdf();
    return pdfFile;
  }
  return undefined;
}
defineExpose({ getPdf });

</script>

<style scoped>

.dont-break {
  break-inside: avoid;
}

.text-field {
  padding: 15px 20px 15px 20px;
  justify-content: space-between;
}

.document-drafter-headline {
  text-transform: uppercase;
  font-size: 20px;
  margin-bottom: 26px;
  margin-top: 0;
}

.document-drafter-sub-headline {
  text-transform: uppercase;
  font-size: 16px;
  margin-bottom: 16px;
  margin-top: 0;
}
</style>
