import { OfferEvaluation, OfferEvaluationData } from '@dims/components';
import { BpqScores, OfferData5086 } from '@/models/Offer';
import { BpqCriteriaWeights, TenderData5086 } from '@/models/Tender';

export type BPQCriteriaEnum =
 | 'DrivingCharacteristics'
 | 'DriversSeatAdjustment'
 | 'EntryExitConditions'
 | 'View'
 | 'CabinNoise'
 | 'Instrumentation'
 | 'Comfort';

const categories: [BPQCriteriaEnum, string][] = [
  ['DrivingCharacteristics', 'Køreegenskaber'],
  ['DriversSeatAdjustment', 'Førersædejustering'],
  ['EntryExitConditions', 'Ind- og udstigningsforhold'],
  ['View', 'Udsyn'],
  ['CabinNoise', 'Kabinestøj'],
  ['Instrumentation', 'Instrumentering'],
  ['Comfort', 'Komfort'],
];
const map: Record<BPQCriteriaEnum, keyof BpqScores> = {
  DrivingCharacteristics: 'drivingCharacteristicsScore',
  DriversSeatAdjustment: 'driversSeatAdjustmentScore',
  EntryExitConditions: 'entryExitConditionsScore',
  View: 'viewScore',
  CabinNoise: 'cabinNoiseScore',
  Instrumentation: 'instrumentationScore',
  Comfort: 'comfortScore',
};

export default {
  getCategories(tenderData: TenderData5086, offerEvaluation: OfferEvaluation) {
    return categories
      .filter((c) => {
        const [key, _label] = c;
        return this.isScoreIncluded(tenderData, key);
      })
      .map((c) => {
        const [key, label] = c;

        // Verbose version to avoid linting errors
        let score = 0;
        let reason = '';

        if (offerEvaluation.data) {
          const offerEvaluationElement = this.getOfferEvaluationElement(offerEvaluation.data, key);

          if (offerEvaluationElement !== undefined) {
            score = offerEvaluationElement.score ?? 0;
            reason = offerEvaluationElement.reason ?? '';
          }
        }

        return {
          key,
          label,
          score,
          weight: this.getWeight(tenderData, key) ?? 0,
          reason,
        };
      });
  },
  getEmptyCategories(tenderData: TenderData5086) {
    return categories
      .filter((c) => {
        const [key, _label] = c;
        return this.isScoreIncluded(tenderData, key);
      })
      .map((c) => {
        const [key, label] = c;
        return {
          key,
          label,
          score: 0,
          weight: 0,
          reason: '',
        };
      });
  },
  getOfferEvaluationElement(offerEvaluationData: OfferEvaluationData[], key: BPQCriteriaEnum) {
    return offerEvaluationData.find((o: OfferEvaluationData) => o.text === key);
  },
  getWeight(tenderData: TenderData5086, key: BPQCriteriaEnum) {
    const propertyName: keyof BpqCriteriaWeights = `bpqCriteria${key}`;
    return tenderData[propertyName] ?? undefined;
  },
  isScoreIncluded(tenderData: TenderData5086, key: BPQCriteriaEnum) {
    return (
      (tenderData.bpqCriteria?.includes(key))
      ?? (!tenderData.bpqCriteria && this.getWeight(tenderData, key) !== undefined)
    );
  },
  getScorePropertyName(key: BPQCriteriaEnum) {
    return map[key];
  },
  getOfferScore(offerData: OfferData5086, key: BPQCriteriaEnum) {
    const propertyName = this.getScorePropertyName(key);
    return offerData[propertyName];
  },
  setOfferScore(offerData: OfferData5086, key: BPQCriteriaEnum, value: number) {
    const scorePropertyName = this.getScorePropertyName(key);
    // eslint-disable-next-line no-param-reassign
    offerData[scorePropertyName] = value;
  },
};
