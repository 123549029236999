<template>
  <div>
    <v-radio-group v-model="selectedVehicleType" :hide-details="true">
      <div class="d-flex align-center">
        <v-radio
          class="ml-6"
          density="compact"
          color="accent"
          :value="vehicleTypePeopleMover"
        >
          <template #label>
            {{ getLabel(vehicleTypePeopleMover) }}
            <DimsTooltip customClass="mb-2">
              <div>
                <div>
                  Biler, der er indrettet til befordring af højst 9 personer -
                  føreren medregnet.
                </div>
                <div class="my-2">
                  <strong>Eksempelvis</strong>
                </div>
                <v-row class="mb-1">
                  <v-col>
                    <ul>
                      <li>Mikrobiler</li>
                      <li>Små personbiler</li>
                      <li>Store personbiler</li>
                      <li>Mellemklasse stationcars</li>
                      <li>Mini MPV</li>
                      <li>MPV 7-sæder</li>
                    </ul>
                  </v-col>
                  <v-col>
                    <ul>
                      <li>Minibiler</li>
                      <li>Mellemklasse personbiler</li>
                      <li>Små stationcars</li>
                      <li>Store stationcars</li>
                      <li>Kompakt MPV</li>
                      <li>4X4</li>
                    </ul>
                  </v-col>
                </v-row>
                <p>Svarende til indregistreringskategori M1.</p>
              </div>
            </DimsTooltip>
          </template>
        </v-radio>

      </div>
      <div class="d-flex align-center">
        <v-radio
          class="ml-6"
          density="compact"
          color="accent"
          :value="vehicleTypeUtility"
        >
          <template #label>
            {{ getLabel(vehicleTypeUtility) }}
            <DimsTooltip customClass="mb-2">
              <div>
                <div class="mb-2">Biler, der er indrettet til:</div>
                <ul>
                  <li>
                    Befordring af mere end 9 personer føreren medregnet, og som har
                    en tilladt totalvægt på ikke over 5.000 kg.
                  </li>
                  <li>
                    Godsbefordring, og som har en tilladt totalvægt på ikke over
                    3.500 kg
                  </li>
                </ul>
                <div class="mt-5 mb-2"><strong>Eksempelvis</strong></div>
                <v-row class="mb-1">
                  <v-col>
                    <ul>
                      <li>Mikrobiler</li>
                      <li>Lange vans</li>
                      <li>Firehjulstrukne ladvogne med enkeltkabine</li>
                      <li>Kassebiler med enkeltkabine</li>
                      <li>Chassisbiler med dobbeltkabine</li>
                      <li>Varevogn</li>
                      <li>Kassebiler med dobbeltkabine</li>
                    </ul>
                  </v-col>
                  <v-col>
                    <ul>
                      <li>Korte vans</li>
                      <li>Mini busser</li>
                      <li>Firehjulstrukne ladvogne med dobbeltkabine</li>
                      <li>Chassisbiler med enkeltkabine</li>
                      <li>Varebil (med firehjulstræk)</li>
                      <li>Kassebiler med firehjulstræk</li>
                      <li>Pick-up med firehjulstræk</li>
                    </ul>
                  </v-col>
                </v-row>
                <p>Svarende til indregistreringskategori M2 og N1.</p>
              </div>
            </DimsTooltip>
          </template>
        </v-radio>
      </div>
      <div class="d-flex align-center">
        <v-radio
          class="ml-6"
          density="compact"
          color="accent"
          :value="vehicleTypeOther"
        >
          <template #label>
            {{ getLabel(vehicleTypeOther) }}
            <DimsTooltip customClass="mb-2">
              <div>
                <div class="mb-2">
                  Køretøjer, der ikke er omfattet af kategorierne "Personbiler" og
                  "Arbejdsbiler".
                </div>
                <div class="my-2">
                  <strong>Eksempelvis</strong>
                </div>
                <v-row>
                  <v-col>
                    <ul>
                      <li>Knallert</li>
                      <li>Motorcykler</li>
                      <li>Traktor</li>
                      <li>Påhængskøretøj</li>
                      <li>Påhængsvogn</li>
                      <li>UTV</li>
                    </ul>
                  </v-col>
                  <v-col>
                    <ul>
                      <li>Scooter</li>
                      <li>Snescootere</li>
                      <li>Motorredskab</li>
                      <li>Traktorpåhængsvogn</li>
                      <li>ATV</li>
                    </ul>
                  </v-col>
                </v-row>
              </div>
            </DimsTooltip>
          </template>
        </v-radio>

      </div>
      <div class="d-flex align-center">
        <v-radio
          class="ml-6"
          density="compact"
          color="accent"
          :value="vehicleTypeLeasing"
          :disabled="leasingDisabled"
        >
          <template #label>
            {{ getLabel(vehicleTypeLeasing) + (leasingDisabled
              ? ` kommer senere. Brug i mellemtiden Ethics og SKI's tildelingskit, der findes i 'Aftaledokumenter' i topmenuen.`
              : '') }}
            <DimsTooltip customClass="mb-2">
              <div>
                <p>
                  Operationel leasing af biler og køretøjer omfattet af kategorierne
                  "Personbiler", "Arbejdsbiler" og "Andre køretøjer".
                </p>
                <p>Svarende til indregistreringskategori M1, M2 eller N1.</p>
              </div>
            </DimsTooltip>
          </template>
        </v-radio>
      </div>
    </v-radio-group>
  </div>
</template>

<script setup lang="ts">
import { ref, computed } from 'vue';
import { DimsTooltip } from '@dims/components';
import { VehicleType } from '@/models';
import VehicleTypesEnum, { vehicleTypeLabels } from '@/models/VehicleTypes';
import { useStore5086 } from '@/store/store5086';

const store5086 = useStore5086();
const leasingDisabled = ref(false);
const vehicleTypePeopleMover = VehicleType.PEOPLE_MOVER;
const vehicleTypeUtility = VehicleType.UTILITY_VEHICLE;
const vehicleTypeOther = VehicleType.OTHER_VEHICLE;
const vehicleTypeLeasing = VehicleType.LEASING;

const selectedVehicleType = computed({
  get(): VehicleType | undefined {
    let vehicleType;
    if (store5086.draftTender) {
      vehicleType = store5086.draftTender.data.vehicleType;
    }
    return vehicleType ?? undefined;
  },

  set(vehicleType: VehicleType | undefined) {
    let value = vehicleType;
    if (vehicleType === VehicleTypesEnum.LEASING) {
      leasingDisabled.value = true;
      value = VehicleTypesEnum.PEOPLE_MOVER;
    }
    if (store5086.draftTender) {
      store5086.setDraftTender({
        ...store5086.draftTender,
        data: { ...store5086.draftTender.data, vehicleType: value },
      });
    }
  },
});

function getLabel(type: VehicleType): string {
  return vehicleTypeLabels(type);
}

</script>
