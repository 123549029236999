enum DeliveryConditionTypesEnum {
  DELIVERY_AT_CUSTOMER = 'DeliveryAtCustomer',
  PICKUP_AT_SUPPLIER = 'PickupAtSupplier',
}

export const deliveryConditionTypes = [
  DeliveryConditionTypesEnum.DELIVERY_AT_CUSTOMER,
  DeliveryConditionTypesEnum.PICKUP_AT_SUPPLIER,
];

export const deliveryConditionTypeLabels = (type: DeliveryConditionTypesEnum) => {
  switch (type) {
    case DeliveryConditionTypesEnum.DELIVERY_AT_CUSTOMER:
      return 'Leveres hos kunde';
    case DeliveryConditionTypesEnum.PICKUP_AT_SUPPLIER:
      return 'Hentes hos leverandør';
    default:
      return 'Unknown delivery condition type';
  }
};

export default DeliveryConditionTypesEnum;
