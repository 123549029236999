const tcoVariables = {
  discountRate: 4,
  gasolinePriceIncreaseRatio: 1.38504607579524,
  dieselPriceIncreaseRatio: 1.44773261983384,
  electricPriceIncreaseRatio: 1.67103739112335,
  priceGasoline: 12.33,
  priceDiesel: 10.83,
  priceElectric: 2.13,
  expectedLifetimeForChargingStations: 10,
} as const;

export default tcoVariables;
