import '@dims/components/dist/dims-components.css';
import { init } from '@dims/components';
import componentProvider from '@/componentProvider';
import App from '@/App.vue';

const siteConfig = {
  agreementId: '50860017',
  agreementNameLong: '50.86 Biler',
};

init(App, document, null, siteConfig, componentProvider);
