<template>
  <div>
    <v-radio-group v-model="selectedAwardType" :hide-details="true">
      <div class="d-flex align-center">
        <v-radio
          density="compact"
          class="ml-6"
          color="accent"
          :value="'TCO'"
        >
          <template #label>
            {{ `${awardTypeLabel('TCO')} (TCO)` }}
            <DimsTooltip customClass="mb-2">
              <p>
                Anvendes hvis køretøjet efter jeres egne retningslinjer <strong>ikke</strong> udgør
                en arbejdsplads. Evalueringen foretages på baggrund af indkøbets
                samlede TCO-pris dvs. Total Cost of Ownership, hvor TCO omfatter
                indkøbspris og livscyklusomkostninger. Den tilbudsgiver med den
                laveste TCO-pris vinder.
              </p>
              <p>
                <strong>Bemærk</strong> Udgør omkostningerne kun en marginal del af
                indkøbets værdi, kan evalueringen foretages på baggrund af
                indkøbsprisen alene.
              </p>
            </DimsTooltip>
          </template>
        </v-radio>

      </div>
      <div class="d-flex align-center align-center">
        <v-radio
          density="compact"
          class="ml-6"
          color="accent"
          :value="'BPQRatio'"
        >
          <template #label>
            {{ `${awardTypeLabel('BPQRatio')} (APV)` }}
            <DimsTooltip customClass="mb-2">
              <p>
                Anvendes hvis bilen efter jeres egne retningslinjer udgør en
                arbejdsplads.
              </p>
              <p>
                Her skal evaluering foretages på baggrund af både en evaluering af
                kvalitet, dvs. en arbejdspladsvurdering (APV) fx med brugertest, og
                en evaluering af pris, dvs. indkøbspris og
                livscyklusomkostninger. Den tilbudsgiver med den bedste samlede
                pointscore vinder.
              </p>
              <p>
                <strong>Bemærk</strong> Har I tidligere foretaget en APV på et
                lignende køretøj, og vurderer at kunne kravspecificere køretøjet så
                den opfylder denne APV, kan du evaluere efter tildelingskriteriet
                "Omkostninger".
              </p>
            </DimsTooltip>
          </template>
        </v-radio>
      </div>
    </v-radio-group>
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue';
import { AwardCriteriaType } from '@dims/components';
import { useStore5086 } from '@/store/store5086';
import { awardCriteriaType } from '@/services';

const store5086 = useStore5086();

const selectedAwardType = computed({
  get(): AwardCriteriaType | undefined {
    let awardType;
    if (store5086.draftTender) {
      awardType = store5086.draftTender.awardCriteriaType;
    }
    return awardType;
  },
  set(type: AwardCriteriaType | undefined) {
    if (store5086.draftTender && type) {
      store5086.setDraftTender({
        ...store5086.draftTender,
        awardCriteriaType: type,
        data: { ...store5086.draftTender.data },
      });
    }
  },
});

function awardTypeLabel(type: AwardCriteriaType): string {
  return awardCriteriaType.getLabel(type);
}

</script>
