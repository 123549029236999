import { ComponentProvider, TenderOverviewOld } from '@dims/components';
import SpecificationViewer from '@/components/Offers/Specification/SpecificationViewer.vue';
import OfferDetails from '@/components/Offers/OfferDetails/OfferDetails.vue';
import OfferSpecificationViewer from '@/components/Offers/Specification/OfferSpecificationViewer.vue';
import OfferSpecificationDialog from '@/components/Offers/Specification/OfferSpecificationDialog.vue';
import OfferSandbox from '@/components/Offers/OfferSandbox.vue';
import PublishTenderConfirmationDialog from '@/components/Tender/TenderOverview/PublishTenderConfirmationDialog.vue';
import TenderMaterial from '@/components/Tender/TenderMaterial/TenderMaterial.vue';
import SpecificationAttachedFiles from '@/components/Tender/TenderOverview/SpecificationAttachedFiles.vue';
import TenderTerminationDialog from '@/components/Tender/Evaluation/TenderTerminationDialog.vue';
import EvaluationOfferList from '@/components/Tender/Evaluation/EvaluationOfferList.vue';
import CreateTender from '@/components/create-tender/CreateTender.vue';
import HomeView from '@/views/HomeView.vue';
import FaqView from '@/views/FaqView.vue';

const componentProvider: ComponentProvider = {
  OfferDetails,
  SpecificationViewer,
  OfferSpecificationViewer,
  OfferSpecificationDialog,
  SpecificationAttachedFiles,
  OfferSandbox,
  PublishTenderConfirmationDialog,
  TenderTerminationDialog,
  CreateTender,
  TenderMaterial,
  EvaluationOfferList,
  HomeView,
  FaqView,
  TenderOverviewOld,
};

export default componentProvider;
