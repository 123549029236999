enum VehicleClassEnum {
    M1 = 'M1',
    M2 = 'M2',
    N1 = 'N1',
    Other = 'Andet'
}

export const vehicleClassTypes = [
  VehicleClassEnum.M1,
  VehicleClassEnum.M2,
  VehicleClassEnum.N1,
  VehicleClassEnum.Other,
];

export const vehicleClassTypeLabels = (type: VehicleClassEnum): string => {
  switch (type) {
    case VehicleClassEnum.M1:
      return 'M1';
    case VehicleClassEnum.M2:
      return 'M2';
    case VehicleClassEnum.N1:
      return 'N1';
    case VehicleClassEnum.Other:
      return 'Andet';
    default:
      return 'Unknown vehicle class type';
  }
};

export const vehicleClassTypeFromLabel = (
  label: string,
): VehicleClassEnum | undefined => vehicleClassTypes
  .find((vc) => vehicleClassTypeLabels(vc) === label);

export default VehicleClassEnum;
