enum VehicleTypesEnum {
  LEASING = 'Leasing',
  OTHER_VEHICLE = 'OtherVehicle',
  PEOPLE_MOVER = 'PeopleMover',
  UTILITY_VEHICLE = 'UtilityVehicle',
}

export const vehicleTypes = [
  VehicleTypesEnum.PEOPLE_MOVER,
  VehicleTypesEnum.UTILITY_VEHICLE,
  VehicleTypesEnum.OTHER_VEHICLE,
  VehicleTypesEnum.LEASING,
];

export const vehicleTypeLabels = (type?: VehicleTypesEnum) => {
  if (!type) {
    return '';
  }
  switch (type) {
    case VehicleTypesEnum.LEASING:
      return 'Operationel leasing';
    case VehicleTypesEnum.OTHER_VEHICLE:
      return 'Andre køretøjer';
    case VehicleTypesEnum.UTILITY_VEHICLE:
      return 'Arbejdsbiler';
    case VehicleTypesEnum.PEOPLE_MOVER:
      return 'Personbiler';
    default:
      return 'Unknown vehicle type';
  }
};

export default VehicleTypesEnum;
