<template>
  <v-row>
    <v-col
      class="d-flex"
      cols="12"
    >
      <v-select density="compact" variant="outlined" v-model="selectedRegion" :items="regionItems" data-cy="region-select">
      </v-select>
    </v-col>
  </v-row>

</template>

<script setup lang="ts">
import { computed } from 'vue';
import { Region, labelForRegion } from '@dims/components';
import { useStore5086 } from '@/store/store5086';

const store5086 = useStore5086();
const regionItems = ([
  'Hovedstaden',
  'Midtjylland',
  'Nordjylland',
  'Sjaelland',
  'Syddanmark',
] as const).map((r) => ({ title: labelForRegion(r), value: r }));

const selectedRegion = computed({
  get() {
    let region;
    if (store5086.draftTender) {
      region = store5086.draftTender.data.region;
    }
    return region ?? undefined;
  },
  set(region: Region | undefined) {
    if (store5086.draftTender) {
      store5086.setDraftTender({
        ...store5086.draftTender,
        data: { ...store5086.draftTender.data, region },
      });
    }
  },
});

</script>

<style scoped>

.v-text-field--outlined :deep(fieldset) {
  border-color: rgb(var(--v-theme-secondary));
}
</style>
